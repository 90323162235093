import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "pointer-events-none flex flex-col w-full h-full checkbox-container" }
const _hoisted_2 = {
  key: 0,
  class: "text-white",
  style: {"font-size":"0.9rem","line-height":"115%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: _normalizeClass(["mt-1.5 checkmark text-xs font-bold text-center", [_ctx.defaultValue === 'checked' && 'bg-fa-orange']])
    }, [
      (_ctx.defaultValue === 'checked')
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, "✕"))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}