import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["id", "name", "value", "disabled", "checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(['checkbox-container', _ctx.isReadOnly && 'cursor-default'])
  }, [
    _createElementVNode("input", {
      class: "mt-1.5 border-none !bg-none !bg-transparent focus:ring-1 focus:ring-offset-0 focus:ring-fa-blue focus:border-fa-blue",
      type: "checkbox",
      id: _ctx.nodeId,
      name: _ctx.valueFieldName,
      value: _ctx.nodeId,
      disabled: _ctx.isReadOnly,
      checked: _ctx.isChecked,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
    }, null, 40, _hoisted_1),
    _createElementVNode("span", {
      class: _normalizeClass(["mt-1.5 checkmark text-xs font-bold text-center", [
        _ctx.isChecked && !_ctx.isReadOnly && 'bg-fa-orange',
        _ctx.isReadOnly && 'bg-fa-turquoise bg-opacity-20'
      ]])
    }, [
      (_ctx.isChecked)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass([_ctx.isReadOnly ? 'text-gray-800' : 'text-white']),
            style: {"font-size":"0.9rem","line-height":"115%"}
          }, "✕", 2))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}