import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "tree-nodes-list" }
const _hoisted_2 = {
  key: 0,
  ref: "cursor",
  class: "tree-cursor",
  style: {"user-select":"none","pointer-events":"none","position":"absolute","left":"0","top":"-9999px"}
}
const _hoisted_3 = ["onMousedown", "onMouseup", "onDrop", "path"]
const _hoisted_4 = {
  key: 0,
  class: "tree-branch"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "tree-title" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "tree-sidebar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tree = _resolveComponent("Tree", true)!

  return (_openBlock(), _createElementBlock("div", {
    ref: "treeRoot",
    class: _normalizeClass(["tree", { 'tree-root': _ctx.isRoot }]),
    onMousemove: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onMousemoveHandler && _ctx.onMousemoveHandler(...args))),
    onMouseleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onMouseleaveHandler && _ctx.onMouseleaveHandler(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isRoot)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, null, 512))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nodes, (node, nodeInd) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["tree-node", { 'tree-selected': node.isSelected }]),
          key: nodeInd
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["tree-node-item", {
            'tree-node-is-leaf': node.isLeaf,
            'tree-node-is-folder': !node.isLeaf
          }]),
            onMousedown: ($event: any) => (_ctx.onNodeMousedownHandler($event, node)),
            onMouseup: ($event: any) => (_ctx.onNodeMouseupHandler($event, node)),
            onDrop: ($event: any) => (_ctx.onExternalDropHandler(node, $event)),
            path: node.pathStr
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.gaps, (gapInd) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "tree-gap",
                key: gapInd
              }))
            }), 128)),
            (_ctx.level && _ctx.showBranches)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _renderSlot(_ctx.$slots, "branch", { node: node }, () => [
                    (!node.isLastChild)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(String.fromCharCode(0x251c)) + _toDisplayString(String.fromCharCode(0x2500)) + "  ", 1))
                      : _createCommentVNode("", true),
                    (node.isLastChild)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(String.fromCharCode(0x2514)) + _toDisplayString(String.fromCharCode(0x2500)) + "  ", 1))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_7, [
              (!node.isLeaf)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: "tree-toggle",
                    onClick: ($event: any) => (_ctx.onToggleHandler($event, node))
                  }, [
                    _renderSlot(_ctx.$slots, "toggle", { node: node }, () => [
                      _createElementVNode("span", null, _toDisplayString(!node.isLeaf ? (node.isExpanded ? "-" : "+") : ""), 1)
                    ])
                  ], 8, _hoisted_8))
                : _createCommentVNode("", true),
              _renderSlot(_ctx.$slots, "title", { node: node }, () => [
                _createTextVNode(_toDisplayString(node.title), 1)
              ]),
              (
                !node.isLeaf && node.children.length == 0 && node.isExpanded
              )
                ? _renderSlot(_ctx.$slots, "empty-node", {
                    key: 1,
                    node: node
                  })
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _renderSlot(_ctx.$slots, "sidebar", { node: node })
            ])
          ], 42, _hoisted_3),
          (node.children && node.children.length && node.isExpanded)
            ? (_openBlock(), _createBlock(_component_Tree, {
                key: 0,
                modelValue: node.children,
                "onUpdate:modelValue": ($event: any) => ((node.children) = $event),
                level: node.level,
                parentInd: nodeInd,
                allowToggleBranch: _ctx.allowToggleBranch,
                edgeSize: _ctx.edgeSize,
                showBranches: _ctx.showBranches,
                onDragover: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
              }, {
                title: _withCtx(({ node }) => [
                  _renderSlot(_ctx.$slots, "title", { node: node }, () => [
                    _createTextVNode(_toDisplayString(node.title), 1)
                  ])
                ]),
                toggle: _withCtx(({ node }) => [
                  _renderSlot(_ctx.$slots, "toggle", { node: node }, () => [
                    _createElementVNode("span", null, _toDisplayString(!node.isLeaf ? (node.isExpanded ? "-" : "+") : ""), 1)
                  ])
                ]),
                sidebar: _withCtx(({ node }) => [
                  _renderSlot(_ctx.$slots, "sidebar", { node: node })
                ]),
                "empty-node": _withCtx(({ node }) => [
                  (
                !node.isLeaf && node.children.length == 0 && node.isExpanded
              )
                    ? _renderSlot(_ctx.$slots, "empty-node", {
                        key: 0,
                        node: node
                      })
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue", "level", "parentInd", "allowToggleBranch", "edgeSize", "showBranches"]))
            : _createCommentVNode("", true)
        ], 2))
      }), 128)),
      (_ctx.isRoot)
        ? _withDirectives((_openBlock(), _createElementBlock("div", {
            key: 1,
            ref: "dragInfo",
            class: "tree-drag-info"
          }, _toDisplayString(_ctx.t("tree.moving", _ctx.draggingNode)), 513)), [
            [_vShow, _ctx.isDragging]
          ])
        : _createCommentVNode("", true)
    ])
  ], 34))
}