
import { ComputedNode } from "@/admin/lib/templateMapper";
import TemplateSectionNode from "@/admin/components/templateEditor/TemplateSectionNode.vue";
import TemplateFooterNode from "@/admin/components/templateEditor/TemplateFooterNode.vue";
import TemplateHeaderNode from "@/admin/components/templateEditor/TemplateHeaderNode.vue";
import TemplateLayoutNode from "@/admin/components/templateEditor/TemplateLayoutNode.vue";
import TemplateControlNode from "@/admin/components/templateEditor/TemplateControlNode.vue";
import { defineComponent, PropType } from "vue";
import { TemplateRegion } from "@/admin/lib/templateEditorContext";

export default defineComponent({
  components: {
    TemplateSectionNode,
    TemplateFooterNode,
    TemplateLayoutNode,
    TemplateControlNode,
    TemplateHeaderNode
  },
  props: {
    region: { type: String as PropType<TemplateRegion>, required: true },
    path: {
      type: Array as PropType<number[]>,
      default: () => []
    },
    sectionIndices: {
      type: Array as PropType<number[]>,
      default: () => []
    },
    node: { type: Object as PropType<ComputedNode>, required: true },
    parents: { type: Array as PropType<ComputedNode[]>, default: () => [] },
    pageNumber: { type: Number as PropType<number>, required: true }
  }
});
