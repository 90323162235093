
import { v4 as uuidv4 } from "uuid";
import { defineComponent, inject, ref, Ref, watch } from "vue";
import { CaseDetailsKey, CaseAppendicesKey } from "./types";
import { useI18n } from "vue-i18n";
import { PlusIcon, InformationCircleIcon } from "@heroicons/vue/outline";
import { useSession } from "@/use/session";
import { useRoute } from "vue-router";
import { routeNames } from "@/router/index";
import { useApiRequest } from "@/use/apiRequest";
import { caseApi } from "@/api";
import {
  CaseUploadFileSizeLimit,
  isPdfConvertable,
  isPreviewableWithoutConversion,
  toBase64
} from "@/api/caseApi";
import { useToast } from "@/use/toast";
import Switch from "@/components/Switch.vue";

export default defineComponent({
  components: {
    PlusIcon,
    Switch,
    InformationCircleIcon
  },
  props: {
    disabled: {
      type: Boolean,
      required: false
    },
    smallButtons: {
      type: Boolean,
      required: false
    },
    showTocToggle: {
      type: Boolean,
      default: false,
      required: false
    },
    isExternal: {
      type: Boolean,
      required: false
    }
  },
  emits: ["appendixAdded"],
  setup(props, { emit }) {
    const MaxNumberOfAppendicesAllowedWithTableOfContents = 36; //Ensures no page wrap. Also handled in backend.

    const { t } = useI18n();
    const caseTemplates = inject(CaseAppendicesKey);
    const includeTableOfContents = ref(false);
    const tableOfContentsMaxLimitReached = ref(false);
    const caseDetails = inject(CaseDetailsKey);
    const { isLoggedIn } = useSession();
    const route = useRoute();
    const isAnonomousMode = ref(false);
    isAnonomousMode.value =
      !isLoggedIn.value &&
      route.name === routeNames.externalAnonymousContractEdit;
    const toast = useToast();

    tableOfContentsMaxLimitReached.value =
      (caseDetails?.value?.appendices.length || 0) >
      MaxNumberOfAppendicesAllowedWithTableOfContents;

    includeTableOfContents.value = tableOfContentsMaxLimitReached.value
      ? false
      : caseDetails?.value?.includeTableOfContents || false;

    const addPlaceholder = () => {
      if (!caseTemplates) return;
      if (!caseDetails?.value) return;

      const id = uuidv4();
      const sortOrder =
        caseTemplates.value.sort((a, b) => a.sortOrder - b.sortOrder)[
          caseTemplates.value.length - 1
        ]?.sortOrder + 1 ?? 0;

      caseDetails.value.appendices = [
        ...caseDetails.value.appendices,
        {
          kind: "Placeholder",
          id,
          sortOrder,
          name: ""
        }
      ];
      emit("appendixAdded", id);
    };

    const addFileAppendix = (
      fileEl: Ref<HTMLInputElement | undefined>,
      file: File,
      fileBase64: string
    ): void => {
      if (!fileEl) return;
      if (!file) return;
      if (!fileBase64) return;
      if (!caseTemplates) return;
      if (!caseDetails?.value) return;

      const id = uuidv4();
      const sortOrder =
        caseTemplates.value.sort((a, b) => a.sortOrder - b.sortOrder)[
          caseTemplates.value.length - 1
        ]?.sortOrder + 1 ?? 0;

      caseDetails.value.appendices = [
        ...caseDetails.value.appendices,
        {
          kind: "Upload",
          name: file.name,
          file: fileBase64,
          id,
          sortOrder,
          fileType: file.name.split(".").pop() ?? ""
        }
      ];

      if (fileEl.value) fileEl.value.value = "";
      emit("appendixAdded", id);
    };

    watch(
      () => includeTableOfContents.value,
      () => {
        if (!caseDetails?.value) return;
        caseDetails.value.includeTableOfContents = includeTableOfContents.value;
      }
    );

    watch(
      () => caseDetails?.value?.appendices.length,
      () => {
        if (
          caseDetails &&
          caseDetails.value &&
          caseDetails.value.appendices.length >
            MaxNumberOfAppendicesAllowedWithTableOfContents
        ) {
          tableOfContentsMaxLimitReached.value = true;
          includeTableOfContents.value = false;
        } else {
          tableOfContentsMaxLimitReached.value = false;
        }
      }
    );

    const file = ref<HTMLInputElement>();
    const addUploadedFile = async () => {
      if (!caseTemplates) return;
      if (!caseDetails?.value) return;

      const filesList = file.value?.files;
      if (!filesList) return;

      const firstFile = filesList[0];
      if (!firstFile) return;

      if (firstFile.size > CaseUploadFileSizeLimit) {
        toast.error(t("contractCustomAppendixReferenceMenu.fileTooLarge"));
        return;
      }

      const firstFileType = firstFile.name.split(".").pop() ?? "";

      if (isPdfConvertable(firstFileType)) {
        useApiRequest(
          props.isExternal
            ? caseApi.convertDocumentToPdfExternal
            : caseApi.convertDocumentToPdf
        )
          .create({
            onResponse: response => {
              if (response.result) {
                toBase64(response.result)
                  .then(base64 => {
                    addFileAppendix(file, firstFile, base64);
                  })
                  .catch(() => {
                    toast.error(
                      t(
                        "contractCustomAppendixReferenceMenu.fileConversionFailed"
                      )
                    );
                  });
              }
            },
            onError: () => {
              toast.error(
                t("contractCustomAppendixReferenceMenu.fileConversionFailed")
              );
            }
          })
          .send({
            caseId: caseDetails.value.id,
            file: firstFile
          });
      } else if (isPreviewableWithoutConversion(firstFileType)) {
        addFileAppendix(file, firstFile, await toBase64(firstFile));
      }
    };

    return {
      t,
      plusIcon: PlusIcon,
      addPlaceholder,
      addUploadedFile,
      file,
      includeTableOfContents,
      tableOfContentsMaxLimitReached,
      isAnonomousMode
    };
  }
});
