import { computed, ComputedRef, Ref } from "vue";

export function usePageNumberIndicator(
  template: Ref<string>,
  pageNumber: Ref<number>,
  pageCount: Ref<number | undefined> | undefined
): ComputedRef<string> {
  return computed(() =>
    template.value
      .replaceAll("{{pageNumber}}", pageNumber.value.toString())
      .replaceAll(
        "{{pageCount}}",
        pageCount?.value === undefined ? "?" : pageCount.value.toString()
      )
  );
}
