
import { defineComponent, PropType } from "vue";
import { CheckCircleIcon } from "@heroicons/vue/outline";
import {
  XIcon,
  ExclamationCircleIcon,
  InformationCircleIcon
} from "@heroicons/vue/solid";

export default defineComponent({
  components: {
    CheckCircleIcon,
    ExclamationCircleIcon,
    InformationCircleIcon,
    XIcon
  },
  props: {
    text: { required: false, type: String as PropType<string> },
    title: { required: true, type: String as PropType<string> },
    type: {
      required: false,
      default: "info",
      type: String as PropType<"info" | "success" | "error">
    }
  }
});
