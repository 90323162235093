import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "bg-white w-full h-full" }
const _hoisted_2 = {
  key: 2,
  class: "w-5 h-5 absolute left-[4px] top-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InformationCircleIcon = _resolveComponent("InformationCircleIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default"),
    (!_ctx.node.values.hideTitle)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["absolute top-0 text-2xs pl-[30px] pr-[6px] w-[150px] font-bold border-r border-gray-200 h-full pt-2", [_ctx.node.values.alignRight ? 'text-right' : 'text-left']])
        }, _toDisplayString(_ctx.node.values.title), 3))
      : _createCommentVNode("", true),
    (_ctx.node.values.bottomBorder)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["absolute bottom-0 h-px right-[32px] bg-gray-200", [_ctx.node.values.hideTitle ? 'left-[32px]' : 'left-[158px]']])
        }, null, 2))
      : _createCommentVNode("", true),
    (_ctx.node.values.helpText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_InformationCircleIcon, {
            onClick: _ctx.onShowHelpText,
            class: "cursor-pointer text-gray-500 hover:text-gray-900 inline w-5 h-5"
          }, null, 8, ["onClick"])
        ]))
      : _createCommentVNode("", true)
  ]))
}