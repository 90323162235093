import { computed, ComputedRef, readonly, Ref } from "vue";
import { OrganizationRole } from "@/api/organizationApi";
import { SessionData, OrganizationSessionData } from "@/api/accountApi";
import { useLocalStorage } from "@vueuse/core";

const userId = useLocalStorage<string | null>("userId", null, {
  listenToStorageChanges: false
});
const organizations = useLocalStorage<OrganizationSessionData[]>(
  "organizations",
  [],
  {
    listenToStorageChanges: false
  }
);

const roles = useLocalStorage<string[]>("roles", [], {
  listenToStorageChanges: false
});
const isSysAdmin = computed(() => roles.value?.includes("SysAdmin"));
const isSupportAdmin = computed(() => roles.value?.includes("SupportAdmin"));
const isAtLeastSupportAdmin = computed(
  () => isSysAdmin.value || isSupportAdmin.value
);

const currentOrganizationId = useLocalStorage<number | null>(
  "currentOrganizationId",
  null,
  { listenToStorageChanges: false }
);

function setOrganizations(orgs: OrganizationSessionData[]) {
  organizations.value = orgs;
}

const currentOrganization = computed(() =>
  organizations.value.find(
    o => o.organizationId === Number(currentOrganizationId.value)
  )
);

const currentCustomerId = computed(
  () =>
    organizations.value.find(
      o => o.organizationId == currentOrganizationId.value
    )?.customerId ?? null
);

const isOrganizationOwner = computed(() =>
  ["Owner"].includes(currentOrganization.value?.role ?? "")
);

const isOrganizationAdmin = computed(() =>
  ["Owner", "Admin"].includes(currentOrganization.value?.role ?? "")
);

const isCustomerOwner = computed(
  () => currentOrganization.value?.isCustomerOwner
);

const isCustomerAdmin = computed(
  () =>
    currentOrganization.value?.isCustomerOwner ||
    currentOrganization.value?.isCustomerAdmin
);

const firstName = useLocalStorage<string | null>("firstName", null, {
  listenToStorageChanges: false
});

const lastName = useLocalStorage<string | null>("lastName", null, {
  listenToStorageChanges: false
});

const currentRole = computed(() => currentOrganization.value?.role);

const isLoggedIn = computed(() => !!userId.value);

function setCurrentOrganization(id: number) {
  currentOrganizationId.value = id;
}

function isAdminInOrganization(organizationId: number): boolean {
  const org = organizations.value.find(
    x => x.organizationId === organizationId
  );
  return org != null && (org.role === "Admin" || org.role === "Owner");
}

function setSession(sessionData: SessionData) {
  userId.value = sessionData.id;
  organizations.value = sessionData.organizations;
  roles.value = sessionData.roles;
  currentOrganizationId.value =
    sessionData.organizations[0]?.organizationId ?? null;
  firstName.value = sessionData.firstName;
  lastName.value = sessionData.lastName;
}

function clearSession() {
  if (userId.value) {
    void fetch("/api/accounts/logout", { method: "POST" });
  }
  userId.value = null;
  organizations.value = [];
  roles.value = [];
  currentOrganizationId.value = null;
  firstName.value = null;
  lastName.value = null;
}

function setNewOrganization(organization: OrganizationSessionData) {
  organizations.value = [...organizations.value, organization];
  currentOrganizationId.value = organization.organizationId;
}

function updateExistingOrganization(id: number, name: string) {
  const organization = organizations.value.find(o => o.organizationId == id);

  if (organization) {
    organization.name = name;
  }
}

export function useSession(): {
  userId: Ref<string | null>;
  organizations: Ref<readonly OrganizationSessionData[]>;
  roles: Ref<readonly string[]>;
  isSysAdmin: ComputedRef<boolean>;
  isSupportAdmin: ComputedRef<boolean>;
  isAtLeastSupportAdmin: ComputedRef<boolean>;
  currentCustomerId: ComputedRef<number | null>;
  currentOrganizationId: Ref<number | null>;
  currentOrganization: ComputedRef<OrganizationSessionData | undefined>;
  isOrganizationOwner: ComputedRef<boolean>;
  isOrganizationAdmin: ComputedRef<boolean>;
  isCustomerOwner: ComputedRef<boolean | undefined>;
  isCustomerAdmin: ComputedRef<boolean | undefined>;
  currentRole: ComputedRef<OrganizationRole | undefined>;
  setOrganizations: (organizations: OrganizationSessionData[]) => void;
  setCurrentOrganization: (organizationId: number) => void;
  isAdminInOrganization: (organizationId: number) => boolean;
  isLoggedIn: ComputedRef<boolean>;
  setSession: (sessionData: SessionData) => void;
  clearSession: () => void;
  setNewOrganization: (organization: OrganizationSessionData) => void;
  updateExistingOrganization: (id: number, name: string) => void;
  firstName: Ref<string | null>;
  lastName: Ref<string | null>;
} {
  return {
    userId: readonly(userId),
    organizations: readonly(organizations),
    roles: readonly(roles),
    isSysAdmin: isSysAdmin,
    isSupportAdmin: isSupportAdmin,
    isAtLeastSupportAdmin: isAtLeastSupportAdmin,
    currentOrganizationId: readonly(currentOrganizationId),
    currentCustomerId,
    currentOrganization,
    isOrganizationOwner,
    isOrganizationAdmin,
    isCustomerOwner,
    isCustomerAdmin,
    currentRole,
    setOrganizations,
    setCurrentOrganization,
    isAdminInOrganization,
    isLoggedIn,
    setSession,
    clearSession,
    setNewOrganization,
    updateExistingOrganization,
    firstName,
    lastName
  };
}
