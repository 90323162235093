import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "disabled", "name", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'flex',
      'flex-col',
      'w-full',
      'h-full',
      _ctx.field.readOnly || _ctx.caseReadOnly
        ? 'bg-fa-turquoise bg-opacity-20'
        : 'bg-control-gray-tint',
      'box-border',
      _ctx.showValidationFailures &&
        _ctx.node.required &&
        !_ctx.field.values.text &&
        'border-red-300 border-2',
      !_ctx.node.values.hasLabel && 'justify-center'
    ])
  }, [
    (_ctx.node.values.hasLabel)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.id,
          class: "text-3xs leading-4 flex-shrink-0 text-gray-800 whitespace-nowrap pl-[3px]"
        }, _toDisplayString(_ctx.node.values.label), 9, _hoisted_1))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("textarea", {
      id: _ctx.id,
      ref: "textareaRef",
      disabled: _ctx.field.readOnly || _ctx.caseReadOnly,
      class: "border-none focus:ring-fa-blue focus:border-fa-blue w-full h-full leading-4 pt-0 pb-1 pl-[6px] pr-[12px] bg-transparent resize-none overflow-hidden",
      style: _normalizeStyle([{"font-size":"0.7rem"}, {
        width:
          _ctx.clientTextScaling < 1 && _ctx.nodeWidth
            ? _ctx.nodeWidth * _ctx.clientTextScaling + 'px'
            : ''
      }]),
      name: 
        _ctx.node.values.entityNameId
          ? 'ef-' + _ctx.node.values.entityNameId + '-' + _ctx.node.values.id
          : _ctx.node.values.id
      ,
      placeholder: _ctx.node.values.placeholder,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.text) = $event))
    }, null, 12, _hoisted_2), [
      [_vModelText, _ctx.text]
    ])
  ], 2))
}