export const measureClientTextWidth = (text: string, font: string): number => {
  const el = document.createElement("span");
  el.style.cssText =
    "position: absolute; visibility: hidden; white-space: nowrap; font: " +
    font;
  el.appendChild(document.createTextNode(text));
  document.body.appendChild(el);
  const textWidth = el.offsetWidth;
  document.body.removeChild(el);
  return textWidth;
};
