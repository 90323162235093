
import { usePageNumberIndicator } from "@/use/pageNumberIndicator";
import { defineComponent, inject, toRefs } from "vue";
import { TemplateEditorContext } from "../lib/templateEditorContext";

export default defineComponent({
  props: {
    value: { type: String, required: true },
    pageNumber: { type: Number, required: true }
  },
  setup(props) {
    const { value, pageNumber } = toRefs(props);

    const editorContext = inject(TemplateEditorContext);

    const pageNumberIndicator = usePageNumberIndicator(
      value,
      pageNumber,
      editorContext?.pageCount
    );

    return { pageNumberIndicator };
  }
});
