import { ApiResponse, apiClient } from "./client";

export const createCasePaymentOrder =
  (
    caseId: number,
    customerId: number,
    quantity: number,
    postPaymentAction: PostCasePaymentAction
  ) =>
  async (): Promise<ApiResponse<CreateCasePaymentOrderResponse>> => {
    const response = await apiClient.post(`payment/case`, {
      data: { caseId, customerId, quantity, postPaymentAction }
    });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as CreateCasePaymentOrderResponse };
    }
  };

export const cancelCasePaymentOrder =
  (referenceId: string) => async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.delete(`payment/case/${referenceId}`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export const completeCasePaymentOrder =
  (referenceId: string) =>
  async (): Promise<ApiResponse<CompleteCasePaymentOrderResponse>> => {
    const response = await apiClient.post(
      `payment/case/${referenceId}/complete`
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as CompleteCasePaymentOrderResponse };
    }
  };

export type PostCasePaymentSaveAction = {
  saveCaseCommand: unknown;
};

export type PostCasePaymentStatusAction = {
  updateCaseStatusCommand: unknown;
};

export type PostCasePaymentAction =
  | PostCasePaymentSaveAction
  | PostCasePaymentStatusAction;

export type CreateCasePaymentOrderResponse = {
  jsSource: string;
  abortUrl: string;
  paymentReferenceId: string;
};

export type PaymentCustomerTransactions = {
  paymentTransaction: PaymentTransaction;
  customerTransaction: CustomerTransaction;
};

export type CustomerTransaction = {
  id: number;
};

export type PaymentTransaction = {
  referenceId: string;
  customerId: number;
  contractCaseId: number;
  createdById: number;
  updatedAt: Date;
  pinsPurchased: number;
  state: PaymentTransactionState;
};
export type PaymentTransactionState =
  | "Created"
  | "Paid"
  | "Completed"
  | "Cancelled"
  | "Error";

export type CompleteCasePaymentOrderResponse = {
  customerTransactionId: number;
  success: boolean;
  jsSource: string;
};
