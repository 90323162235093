import { ApiResponse, apiClient } from "./client";

export const getMemberships =
  () => async (): Promise<ApiResponse<Membership[]>> => {
    const response = await apiClient.get("/memberships");
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as Membership[] };
    }
  };

export type Membership = {
  id: string;
  name: string;
};
