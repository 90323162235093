import { ApiResponse, apiClient } from "./client";
import { UserOrganization } from "./organizationApi";

export const getCurrentUser =
  () => async (): Promise<ApiResponse<CurrentUser>> => {
    const response = await apiClient.get(`/users`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as CurrentUser };
    }
  };

export const getUsers =
  (query: string) => async (): Promise<ApiResponse<FilteredUsers>> => {
    const response = await apiClient.get(`/users/filter?${query}`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as FilteredUsers };
    }
  };

export const getUsersAsCsv =
  (query: string) => async (): Promise<ApiResponse<Blob>> => {
    const response = await apiClient.get(`/users/filter?${query}`, {
      responseType: "blob"
    });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as Blob };
    }
  };

export const getUserDetails =
  (id: string) => async (): Promise<ApiResponse<CurrentUser>> => {
    const response = await apiClient.get(`/users/${id}/details`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as CurrentUser };
    }
  };

export const updateCurrentUser =
  (firstName: string, lastName: string) =>
  async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.put(`/users`, {
      data: { firstName, lastName }
    });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export const confirmUserEmail =
  (id: string) => async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.post(`/users/${id}/confirm-email`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export type CurrentUser = {
  id: string;
  firstName: string;
  lastName: string;
  created?: Date;
  lastLogin?: Date;
  email: string;
  organizations: UserOrganization[];
  emailConfirmed: boolean;
};

export type FilteredUsers = {
  records: FilteredUserItem[];
  page: number;
  pageSize: number;
  totalRecords: number;
};

export type FilteredUserItem = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  emailConfirmed: boolean;
};
