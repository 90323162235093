import { RaygunPayload } from "raygun4js";

interface RaygunBreadcrumb {
  message: string;
  CustomData: {
    baseUrl: string;
    requestURL: string;
    responseURL: string;
  };
}

interface RaygunBreadcrumbsPayload {
  Details: {
    Breadcrumbs: RaygunBreadcrumb[];
  };
}

export const removeSensitiveData = (payload: RaygunPayload): RaygunPayload => {
  const payloadWithBreadcrumbs = payload as unknown as RaygunBreadcrumbsPayload;
  const searchQuerystringRegex = /([?&])(search=)((?![?&#=]).)*/g;
  const replacement = "$1$2****";
  payloadWithBreadcrumbs?.Details?.Breadcrumbs?.forEach(value => {
    if (value?.CustomData?.baseUrl?.endsWith("/filter")) {
      value.message = value.message?.replaceAll(
        searchQuerystringRegex,
        replacement
      );
      value.CustomData.requestURL = value.CustomData.requestURL?.replaceAll(
        searchQuerystringRegex,
        replacement
      );
      value.CustomData.responseURL = value.CustomData.responseURL?.replaceAll(
        searchQuerystringRegex,
        replacement
      );
    }
  });
  return payloadWithBreadcrumbs as unknown as RaygunPayload;
};
