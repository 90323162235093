import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "m-4"
}
const _hoisted_2 = { class: "mt-8 flex items-center md:w-5/6" }
const _hoisted_3 = { class: "ml-2 text-sm text-gray-500" }
const _hoisted_4 = {
  key: 1,
  class: "m-4"
}
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "truncate" }
const _hoisted_7 = ["title", "disabled"]
const _hoisted_8 = { class: "truncate" }
const _hoisted_9 = {
  key: 0,
  class: "mt-5 flex justify-between items-center"
}
const _hoisted_10 = { class: "mr-4 mt-1 text-sm font-semibold text-gray-500" }
const _hoisted_11 = {
  key: 1,
  class: "text-xs text-gray-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InformationCircleIcon = _resolveComponent("InformationCircleIcon")!
  const _component_PlusIcon = _resolveComponent("PlusIcon")!
  const _component_Switch = _resolveComponent("Switch")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isAnonomousMode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", null, [
              _createVNode(_component_InformationCircleIcon, { class: "text-fa-orange inline w-6 h-6" })
            ]),
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.t("contractCustomAppendixReferenceMenu.noAppendingInAnonymousMode")), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isAnonomousMode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("input", {
            id: "contract-file-upload-input",
            type: "file",
            ref: "file",
            accept: ".jpeg,.jpg,.png,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx",
            style: {"display":"none"},
            onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addUploadedFile && _ctx.addUploadedFile(...args)))
          }, null, 544),
          _createElementVNode("button", {
            class: _normalizeClass([_ctx.smallButtons ? 'text-xs' : 'text-sm', "flex items-center px-4 py-2 w-full bg-fa-orange text-white font-semibold focus:outline-none hover:shadow disabled:bg-gray-400 disabled:cursor-default"]),
            disabled: _ctx.disabled,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$refs.file.click()))
          }, [
            _createVNode(_component_PlusIcon, {
              class: _normalizeClass([_ctx.smallButtons ? 'h-4 w-4' : 'h-5 w-5', "mr-2"])
            }, null, 8, ["class"]),
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.t("contractCustomAppendixReferenceMenu.uploadFile")), 1)
          ], 10, _hoisted_5),
          _createElementVNode("button", {
            class: _normalizeClass([_ctx.smallButtons ? 'text-xs mt-2' : 'text-sm mt-4', "flex items-center px-4 py-2 w-full bg-fa-orange text-white font-semibold focus:outline-none hover:shadow disabled:bg-gray-400 disabled:cursor-default"]),
            title: _ctx.t('contractCustomAppendixReferenceMenu.tooltip'),
            disabled: _ctx.disabled,
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.addPlaceholder && _ctx.addPlaceholder(...args)))
          }, [
            _createVNode(_component_PlusIcon, {
              class: _normalizeClass([_ctx.smallButtons ? 'h-4 w-4' : 'h-5 w-5', "mr-2"])
            }, null, 8, ["class"]),
            _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.t("contractCustomAppendixReferenceMenu.addPlaceholder")), 1)
          ], 10, _hoisted_7),
          (_ctx.showTocToggle)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.t("contractCustomAppendixReferenceMenu.includeToc")), 1),
                _createVNode(_component_Switch, {
                  modelValue: _ctx.includeTableOfContents,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.includeTableOfContents) = $event)),
                  disabled: _ctx.tableOfContentsMaxLimitReached
                }, null, 8, ["modelValue", "disabled"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.showTocToggle && _ctx.tableOfContentsMaxLimitReached)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.t("contractCustomAppendixReferenceMenu.includeTocMaxLimit")), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}