
import { ComputedNode } from "@/admin/lib/templateMapper";
import { computed, defineComponent, inject, PropType, toRefs } from "vue";
import { renderMarkdown } from "@/utils/markdownHelpers";
import { useI18n } from "vue-i18n";
import { PageCountKey } from "./types";

export default defineComponent({
  props: {
    node: { type: Object as PropType<ComputedNode>, required: true },
    pageNumber: { type: Number as PropType<number>, required: true }
  },
  setup(props) {
    const { node, pageNumber } = toRefs(props);
    const { t } = useI18n();

    const pageCount = inject(PageCountKey);
    const showSigningBoxes = computed(
      () => pageCount?.value !== pageNumber.value
    );

    const value = computed(() =>
      renderMarkdown(node.value.values?.text?.toString() ?? "")
    );

    return { showSigningBoxes, value, t };
  }
});
