import { InjectionKey, Ref } from "vue";
import { NodeDefinition } from "@/api/types";
import { ComputedNode } from "./templateMapper";
import { TemplateSection } from "./templateSection";

export type HoverType = "layout" | "control" | undefined;
export type HoverPlacement = "before" | "inside" | "after" | undefined;

export type TemplateRegion = "header" | "document" | "footer";

export type ControlType =
  | "text"
  | "layout"
  | "input"
  | "checkboxGroup"
  | "checkbox"
  | "dropdown"
  | "appendixReference"
  | "appendixNumber"
  | "pageNumberIndicator"
  | "clipboard";

export type Clipboard = {
  json: string;
  isBaseLayout: boolean;
};

export const collectNodeIds = (sections: TemplateSection[]): string[] => {
  const findNodeIds = (nodes: NodeDefinition[]): string[] => [
    ...nodes.reduce(
      (acc: string[], rn: NodeDefinition) =>
        "values" in rn.properties &&
        rn.properties.values &&
        "id" in rn.properties.values
          ? [...acc, rn.properties.values.id, ...findNodeIds(rn.children)]
          : [...acc, ...findNodeIds(rn.children)],
      [] as string[]
    )
  ];
  return findNodeIds(sections.map(r => r.nodeDefinition));
};

export const getUniqueNodeIdFactory = (
  existingIds: string[]
): (() => (baseId: string) => string) => {
  return () => {
    const triedIds: string[] = [];
    return (baseId: string) => {
      const allIds = [...existingIds, ...triedIds];
      if (!allIds.includes(baseId)) {
        triedIds.push(baseId);
        return baseId;
      }
      let i = 1;
      let id = `${baseId}${i++}`;
      while (allIds.includes(id)) {
        id = `${baseId}${i++}`;
      }
      triedIds.push(id);
      return id;
    };
  };
};

export const getUniqueNodeId = (
  existingIds: string[],
  baseId: string
): string => {
  if (!existingIds.includes(baseId)) {
    return baseId;
  }
  let i = 1;
  let id = `${baseId}${i++}`;
  while (existingIds.includes(id)) {
    id = `${baseId}${i++}`;
  }
  return id;
};

export const findNodePath = (
  nodes: NodeDefinition[],
  node: NodeDefinition,
  path: number[]
): { node: NodeDefinition; path: number[] } | null => {
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i] === node) {
      return { node: nodes[i], path: [...path, i] };
    }
  }
  const foundInChildren = nodes
    .map((n, i) => findNodePath(n.children, node, [...path, i]))
    .filter(m => m !== null);
  if (foundInChildren.length === 1) {
    return foundInChildren[0];
  }

  return null;
};

export const findNodeById = (
  id: number,
  nodes: NodeDefinition[]
): NodeDefinition | null => {
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i].id === id) {
      return nodes[i];
    }
  }

  for (let i = 0; i < nodes.length; i++) {
    const node = findNodeById(id, nodes[i].children);
    if (node) {
      return node;
    }
  }

  return null;
};

export type GetUniqueIdFunction = (baseId: string) => string;

export const TemplateEditorContext: InjectionKey<{
  addControl(
    kind: ControlType,
    region: TemplateRegion,
    parentPath: number[],
    index?: number
  ): void;
  moveControl(
    fromRegion: TemplateRegion,
    fromPath: number[],
    toRegion: TemplateRegion,
    toPath: number[],
    position: "before" | "after"
  ): void;
  moveSection(
    fromIndex: number,
    toIndex: number,
    position: "before" | "after"
  ): void;
  removeNode(region: TemplateRegion, path: number[]): void;
  canRemoveNode(path: number[]): boolean;
  ensureOnlyOneFieldControlsAppendixName(fieldId: string): void,
  selectNodePath(region: TemplateRegion, path: number[]): void;
  getTemporaryId(): number;
  setClipboard(id: number, isBaseLayout: boolean): void;
  isSelected(region: TemplateRegion, path: number[]): boolean;
  hasClipboard: Ref<boolean>;
  insertBefore: Ref<boolean>;
  isDragging: Ref<boolean>;
  dragInside: Ref<ComputedNode | undefined>;
  dragMessage: Ref<string | undefined>;
  selectedPath: Ref<number[]>;
  selectedRegion: Ref<TemplateRegion | undefined>;
  nodeIds: Ref<string[]>;
  hoverType: Ref<HoverType>;
  hoverKind: Ref<string>;
  hoverPlacement: Ref<HoverPlacement>;
  hoverIndex: Ref<number | undefined>;
  hoverPath: Ref<number[] | undefined>;
  hoverRegion: Ref<TemplateRegion | undefined>;
  pageCount: Ref<number | undefined>;
}> = Symbol();
