import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["data-kind"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContractNode = _resolveComponent("ContractNode", true)!
  const _component_ContractNodeSection = _resolveComponent("ContractNodeSection")!
  const _component_ContractNodeHeader = _resolveComponent("ContractNodeHeader")!
  const _component_ContractNodeFooter = _resolveComponent("ContractNodeFooter")!
  const _component_ContractNodeCheckbox = _resolveComponent("ContractNodeCheckbox")!
  const _component_ContractNodeTextField = _resolveComponent("ContractNodeTextField")!
  const _component_ContractNodeDropdown = _resolveComponent("ContractNodeDropdown")!
  const _component_ContractNodeText = _resolveComponent("ContractNodeText")!
  const _component_ContractNodeCustomText = _resolveComponent("ContractNodeCustomText")!
  const _component_ContractNodeAppendixReference = _resolveComponent("ContractNodeAppendixReference")!
  const _component_ContractNodeAppendixNumber = _resolveComponent("ContractNodeAppendixNumber")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.style),
    "data-kind": _ctx.node.kind,
    class: _normalizeClass([
      'border-gray-200 bg-white',
      _ctx.insideCustomTextSection ? 'relative' : 'absolute',
      _ctx.node.values && _ctx.node.values.topBorder && _ctx.node.kind === 'Layout'
        ? 'border-t'
        : '',
      _ctx.node.values && _ctx.node.values.rightBorder && _ctx.node.kind === 'Layout'
        ? 'border-r'
        : '',
      _ctx.node.values && _ctx.node.values.bottomBorder && _ctx.node.kind === 'Layout'
        ? 'border-b'
        : '',
      _ctx.node.values && _ctx.node.values.leftBorder && _ctx.node.kind === 'Layout'
        ? 'border-l'
        : ''
    ])
  }, [
    (_ctx.node.kind === 'Section')
      ? (_openBlock(), _createBlock(_component_ContractNodeSection, {
          key: 0,
          index: _ctx.index,
          node: _ctx.node
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.node.children, (n, i) => {
              return (_openBlock(), _createBlock(_component_ContractNode, {
                index: _ctx.index,
                key: i,
                node: n,
                pageNumber: _ctx.pageNumber,
                caseReadOnly: _ctx.caseReadOnly,
                showValidationFailures: _ctx.showValidationFailures,
                insideCustomTextSection: _ctx.isCustomTextSection(_ctx.node)
              }, null, 8, ["index", "node", "pageNumber", "caseReadOnly", "showValidationFailures", "insideCustomTextSection"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["index", "node"]))
      : (_ctx.node.kind === 'Header')
        ? (_openBlock(), _createBlock(_component_ContractNodeHeader, {
            key: 1,
            node: _ctx.node,
            pageNumber: _ctx.pageNumber,
            logo: _ctx.logo,
            logoExtension: _ctx.logoExtension
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.node.children, (n, i) => {
                return (_openBlock(), _createBlock(_component_ContractNode, {
                  key: i,
                  node: n,
                  pageNumber: _ctx.pageNumber,
                  caseReadOnly: _ctx.caseReadOnly,
                  showValidationFailures: _ctx.showValidationFailures
                }, null, 8, ["node", "pageNumber", "caseReadOnly", "showValidationFailures"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["node", "pageNumber", "logo", "logoExtension"]))
        : (_ctx.node.kind === 'Footer')
          ? (_openBlock(), _createBlock(_component_ContractNodeFooter, {
              key: 2,
              node: _ctx.node,
              pageNumber: _ctx.pageNumber
            }, null, 8, ["node", "pageNumber"]))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
              (_ctx.node.kind === 'Layout' && _ctx.node.layoutKind === 'Checkbox')
                ? (_openBlock(), _createBlock(_component_ContractNodeCheckbox, {
                    key: 0,
                    node: _ctx.node,
                    groupId: _ctx.groupId,
                    exclusive: _ctx.exclusive,
                    pageNumber: _ctx.pageNumber,
                    caseReadOnly: _ctx.caseReadOnly,
                    showValidationFailures: _ctx.showValidationFailures
                  }, null, 8, ["node", "groupId", "exclusive", "pageNumber", "caseReadOnly", "showValidationFailures"]))
                : (
          _ctx.node.kind === 'Layout' && _ctx.node.layoutKind === 'CheckboxGroup'
        )
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.node.children, (n, i) => {
                      return (_openBlock(), _createBlock(_component_ContractNode, {
                        key: i,
                        node: n,
                        groupId: _ctx.node.values.id,
                        exclusive: _ctx.node.values.exclusive,
                        pageNumber: _ctx.pageNumber,
                        caseReadOnly: _ctx.caseReadOnly,
                        showValidationFailures: _ctx.showValidationFailures
                      }, null, 8, ["node", "groupId", "exclusive", "pageNumber", "caseReadOnly", "showValidationFailures"]))
                    }), 128))
                  : (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.node.children, (n, i) => {
                      return (_openBlock(), _createBlock(_component_ContractNode, {
                        index: _ctx.index,
                        key: i,
                        node: n,
                        labelFor: _ctx.labelFor,
                        groupId: _ctx.groupId,
                        exclusive: _ctx.exclusive,
                        pageNumber: _ctx.pageNumber,
                        logo: _ctx.logo,
                        logoExtension: _ctx.logoExtension,
                        caseReadOnly: _ctx.caseReadOnly,
                        showValidationFailures: _ctx.showValidationFailures,
                        insideCustomTextSection: _ctx.insideCustomTextSection
                      }, null, 8, ["index", "node", "labelFor", "groupId", "exclusive", "pageNumber", "logo", "logoExtension", "caseReadOnly", "showValidationFailures", "insideCustomTextSection"]))
                    }), 128))
            ], 64)),
    (_ctx.node.kind === 'Control')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
          (_ctx.node.control === 'TextField')
            ? (_openBlock(), _createBlock(_component_ContractNodeTextField, {
                key: 0,
                node: _ctx.node,
                caseReadOnly: _ctx.caseReadOnly,
                showValidationFailures: _ctx.showValidationFailures,
                nodeWidth: _ctx.nodeWidth
              }, null, 8, ["node", "caseReadOnly", "showValidationFailures", "nodeWidth"]))
            : (_ctx.node.control === 'Dropdown')
              ? (_openBlock(), _createBlock(_component_ContractNodeDropdown, {
                  key: 1,
                  node: _ctx.node,
                  caseReadOnly: _ctx.caseReadOnly
                }, null, 8, ["node", "caseReadOnly"]))
              : (_ctx.node.control === 'Text')
                ? (_openBlock(), _createBlock(_component_ContractNodeText, {
                    key: 2,
                    node: _ctx.node,
                    labelFor: _ctx.labelFor,
                    caseReadOnly: _ctx.caseReadOnly
                  }, null, 8, ["node", "labelFor", "caseReadOnly"]))
                : (_ctx.node.control === 'CustomText')
                  ? (_openBlock(), _createBlock(_component_ContractNodeCustomText, {
                      key: 3,
                      node: _ctx.node,
                      caseReadOnly: _ctx.caseReadOnly
                    }, null, 8, ["node", "caseReadOnly"]))
                  : (_ctx.node.control === 'AppendixReference')
                    ? (_openBlock(), _createBlock(_component_ContractNodeAppendixReference, {
                        key: 4,
                        node: _ctx.node,
                        caseReadOnly: _ctx.caseReadOnly
                      }, null, 8, ["node", "caseReadOnly"]))
                    : (_ctx.node.control === 'AppendixNumber')
                      ? (_openBlock(), _createBlock(_component_ContractNodeAppendixNumber, {
                          key: 5,
                          node: _ctx.node,
                          caseReadOnly: _ctx.caseReadOnly
                        }, null, 8, ["node", "caseReadOnly"]))
                      : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ], 14, _hoisted_1))
}