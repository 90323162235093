import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TemplateSectionNode = _resolveComponent("TemplateSectionNode")!
  const _component_TemplateLayoutNode = _resolveComponent("TemplateLayoutNode")!
  const _component_TemplateHeaderNode = _resolveComponent("TemplateHeaderNode")!
  const _component_TemplateFooterNode = _resolveComponent("TemplateFooterNode")!
  const _component_TemplateControlNode = _resolveComponent("TemplateControlNode")!

  return (_ctx.node.kind === 'Section')
    ? (_openBlock(), _createBlock(_component_TemplateSectionNode, {
        key: 0,
        region: _ctx.region,
        node: _ctx.node,
        path: _ctx.path,
        pageNumber: _ctx.pageNumber
      }, null, 8, ["region", "node", "path", "pageNumber"]))
    : (_ctx.node.kind === 'Layout')
      ? (_openBlock(), _createBlock(_component_TemplateLayoutNode, {
          key: 1,
          region: _ctx.region,
          node: _ctx.node,
          path: _ctx.path,
          sectionIndices: _ctx.sectionIndices,
          parents: _ctx.parents,
          pageNumber: _ctx.pageNumber
        }, null, 8, ["region", "node", "path", "sectionIndices", "parents", "pageNumber"]))
      : (_ctx.node.kind === 'Header')
        ? (_openBlock(), _createBlock(_component_TemplateHeaderNode, {
            key: 2,
            region: _ctx.region,
            node: _ctx.node,
            path: _ctx.path,
            pageNumber: _ctx.pageNumber
          }, null, 8, ["region", "node", "path", "pageNumber"]))
        : (_ctx.node.kind === 'Footer')
          ? (_openBlock(), _createBlock(_component_TemplateFooterNode, {
              key: 3,
              region: _ctx.region,
              node: _ctx.node,
              path: _ctx.path,
              pageNumber: _ctx.pageNumber
            }, null, 8, ["region", "node", "path", "pageNumber"]))
          : (_openBlock(), _createBlock(_component_TemplateControlNode, {
              key: 4,
              region: _ctx.region,
              node: _ctx.node,
              path: _ctx.path,
              parents: _ctx.parents,
              pageNumber: _ctx.pageNumber
            }, null, 8, ["region", "node", "path", "parents", "pageNumber"]))
}