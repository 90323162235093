import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col w-full h-full" }
const _hoisted_2 = { class: "whitespace-nowrap" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "break-words whitespace-normal markdown-container",
        innerHTML: _ctx.compiledMarkdown
      }, null, 8, _hoisted_3)
    ])
  ]))
}