
import { useI18n } from "vue-i18n";
import { ComputedNode } from "@/admin/lib/templateMapper";
import { computed, defineComponent, inject, PropType, ref, toRefs } from "vue";
import {
  TemplateRegion,
  TemplateEditorContext
} from "@/admin/lib/templateEditorContext";
import Checkmark from "@/admin/components/Checkmark.vue";

type HoverBox = {
  top: string;
  left: string;
  width: string;
  height: string;
};

type HoverType = "layout" | "control" | undefined;
type HoverPlacement = "before" | "inside" | "after" | undefined;

export default defineComponent({
  components: {
    Checkmark
  },
  props: {
    region: { type: String as PropType<TemplateRegion>, required: true },
    path: { type: Array as PropType<number[]>, required: true },
    sectionIndices: {
      type: Array as PropType<number[]>,
      required: false,
      default: () => []
    },
    node: { type: Object as PropType<ComputedNode>, required: true },
    parents: { type: Array as PropType<ComputedNode[]>, default: () => [] },
    pageNumber: { type: Number as PropType<number>, required: true }
  },
  setup(props) {
    const { region, path, node, parents } = toRefs(props);
    const { t } = useI18n();

    const hoverBox = ref<HoverBox | undefined>();
    const layout = ref<InstanceType<typeof HTMLDivElement>>();
    const editorContext = inject(TemplateEditorContext);

    const isSelected = computed(
      () =>
        !!path.value.length &&
        !!editorContext?.isSelected(region.value, path.value)
    );

    const checkmarkChecked = computed(
      () =>
        props.node.layoutKind === "Checkbox" &&
        (props.node.values?.defaultValue as boolean)
    );

    const canBeRemoved = computed(
      () => path.value.length && editorContext?.canRemoveNode(path.value)
    );

    const style = computed(() => ({
      top: `${node.value.top}px`,
      left: `${node.value.left}px`,
      width: `${node.value.width}px`,
      height: `${node.value.height}px`
    }));

    const selectNode = () => {
      if (!editorContext || !path.value.length) return;

      editorContext.selectNodePath(region.value, path.value);
    };

    const addControl = (e: DragEvent) => {
      if (!editorContext || !props.path.length) return;

      const control = e.dataTransfer?.getData("control");
      if (
        control !== "layout" &&
        control !== "text" &&
        control !== "input" &&
        control !== "checkboxGroup" &&
        control !== "checkbox" &&
        control !== "dropdown" &&
        control !== "appendixReference" &&
        control !== "appendixNumber" &&
        control !== "clipboard" &&
        control !== "pageNumberIndicator"
      )
        return;

      const hoverType = editorContext.hoverType.value;
      const hoverPlacement = editorContext.hoverPlacement.value;
      const hoverIndex = editorContext.hoverIndex.value;
      let path = editorContext.hoverPath.value;

      let index = hoverIndex;
      if (path) {
        if (hoverType === "layout") {
          switch (hoverPlacement) {
            case "before":
              index = path[path.length - 1];
              path = path.slice(0, -1);
              break;

            case "after":
              index = path[path.length - 1] + 1;
              path = path.slice(0, -1);
              break;
          }
        } else if (hoverPlacement === "after" && index !== undefined) {
          index++;
        }
        editorContext.addControl(control, region.value, path, index);
      }
    };

    const onDragEnter = () => {
      if (editorContext && !path.value.length) {
        editorContext.dragInside.value = undefined;
      } else if (editorContext && path.value.length) {
        editorContext.dragInside.value = node.value;
      }
    };

    const setHoverStates = (
      type?: HoverType,
      placement?: HoverPlacement,
      control?: ComputedNode,
      index?: number
    ) => {
      if (!editorContext) {
        return;
      }

      const oldHoverType = editorContext.hoverType.value;
      const oldHoverKind = editorContext.hoverKind.value;
      const oldHoverPlacement = editorContext.hoverPlacement.value;
      const oldHoverIndex = editorContext.hoverIndex.value;

      if (oldHoverType !== type) {
        editorContext.hoverType.value = type;
      }
      if (oldHoverPlacement !== placement) {
        editorContext.hoverPlacement.value = placement;
      }
      if (oldHoverIndex !== index) {
        editorContext.hoverIndex.value = index;
      }

      const setDragMessage = (
        newValue: string,
        nodeType: HoverType,
        kindType: string,
        placement?: HoverPlacement
      ) => {
        if (
          oldHoverKind !== kindType ||
          oldHoverType !== nodeType ||
          oldHoverPlacement !== placement
        ) {
          if (oldHoverKind !== kindType) {
            editorContext.hoverKind.value = kindType;
          }
          editorContext.dragMessage.value = newValue;
        }
      };

      if (type === undefined) {
        editorContext.dragMessage.value = undefined;
        return;
      }

      if (editorContext) {
        switch (type) {
          case "layout": {
            const kind = control?.layoutKind as string;
            switch (placement) {
              case "before":
                setDragMessage(
                  `${t("templateEditor.hoverPositionBefore")}${kind}`,
                  type,
                  kind,
                  placement
                );
                break;
              case "inside":
                setDragMessage(
                  `${t("templateEditor.hoverPositionInside")}${kind}`,
                  type,
                  kind,
                  placement
                );
                break;
              case "after":
                setDragMessage(
                  `${t("templateEditor.hoverPositionAfter")}${kind}`,
                  type,
                  kind,
                  placement
                );
                break;
            }
            break;
          }
          case "control": {
            if (!control) {
              break;
            }
            const id =
              control.values && "id" in control.values
                ? (control.values.id as string)
                : (control.control as string);
            if (id === undefined) {
              break;
            }
            switch (placement) {
              case "before":
                setDragMessage(
                  `${t("templateEditor.hoverPositionBefore")}${id}`,
                  type,
                  id,
                  placement
                );
                break;
              case "after":
                setDragMessage(
                  `${t("templateEditor.hoverPositionAfter")}${id}`,
                  type,
                  id,
                  placement
                );
                break;
            }
            break;
          }
        }
      }
    };

    const setHoverRegion = (newRegion: TemplateRegion) => {
      if (editorContext && editorContext.hoverRegion.value !== newRegion) {
        editorContext.hoverRegion.value = newRegion;
      }
    };

    const setHoverPath = (newPath: number[]) => {
      if (
        editorContext &&
        JSON.stringify(editorContext.hoverPath.value) !==
          JSON.stringify(newPath)
      ) {
        editorContext.hoverPath.value = newPath;
      }
    };

    /*
     * Sets hover state for the closest control in the same row as the hovered
     * point. This is needed in vertical layouts when one or more controls have
     * a fixed width(See A, B, C, D). This is also needed in a horizontal layout
     * where the controls have a fixed width(See E, F).
     *
     *   A                      B                       C
     * |  ccccccccccccccc  |  |  cccccccccccccccc  |  |  cccccccc         |
     * |  ccccccccccccccc  |  |  cccccccccccccccc  |  |  cccchhhh    p    |
     * |  cccchhhh    p    |  |    p     hhhhcccc  |  |  cccccccc         |
     *   D                      E                       F
     * |         cccccccc  |  |  cccc cccc cchh p  |  |  p hhcc cccc cccc |
     * |    p    hhhhcccc  |  |                    |  |                   |
     * |         cccccccc  |  |                    |  |                   |
     *
     * c = space occupied by a control
     * h = space occupied by the hover box
     * p = pointer
     */
    const setHoverStatesForClosestControl = (x: number, y: number) => {
      const sameRowChildren = (node.value.children ?? [])
        .map((c, i) => ({ node: c, index: i }))
        .filter(
          c =>
            c.node.kind !== "Layout" &&
            y >= c.node.top &&
            y <= c.node.top + c.node.height &&
            (x < c.node.left || x > c.node.left + c.node.width)
        );
      let index = -1;
      let placement: "before" | "after" = "after";
      let minDistance = 99999;
      for (let i = 0; i < sameRowChildren.length; i++) {
        const child = sameRowChildren[i];
        const left = child.node.left;
        const right = child.node.left + child.node.width;
        const leftDistance = Math.abs(left - x);
        const rightDistance = Math.abs(right - x);
        const distance = Math.min(leftDistance, rightDistance);

        if (minDistance > distance) {
          minDistance = distance;
          index = child.index;
          if (x < child.node.left) {
            placement = "before";
          }
        }
      }
      if (editorContext && index !== -1) {
        setHoverStates(
          "control",
          placement,
          (node.value.children ?? [])[index],
          index
        );
        setHoverRegion(region.value);
        setHoverPath(path.value);
      }
    };

    const getSafeAreaParents = (placement: "before" | "after") => {
      const parents = [...props.parents.slice(0, -1)];

      let j = -1;
      for (; j < parents.length; j++) {
        const parent = j === -1 ? node.value : parents[j];
        const parentsParent = parents[j + 1];
        if (
          parent &&
          placement === "before" &&
          !parent.isFirstChild &&
          parentsParent?.flexDirection !== "FlexDirectionColumn"
        ) {
          break;
        }
        if (
          parent &&
          placement === "after" &&
          !parent.isLastChild &&
          parentsParent?.flexDirection !== "FlexDirectionColumn"
        ) {
          break;
        }
      }

      return parents.slice(0, j + 1);
    };

    const setEdgeHoverStates = (
      x: number,
      safeAreaSize: number,
      width: number
    ) => {
      const placement = x < width / 2 ? "before" : "after";
      const filteredParents = getSafeAreaParents(placement);

      const sliceSteps = Math.min(4, filteredParents.length);
      const sliceSize = safeAreaSize / (sliceSteps + 1);

      for (let i = sliceSteps; i >= 0; i--) {
        const areaStart = safeAreaSize - sliceSize * i;
        if (
          editorContext &&
          ((placement === "before" && x <= areaStart) ||
            (placement === "after" && x >= width - areaStart))
        ) {
          setHoverRegion(region.value);
          setHoverPath(path.value.slice(0, i === 0 ? undefined : -i));
          setHoverStates(
            "layout",
            placement,
            i === 0 ? node.value : filteredParents[i - 1]
          );
          break;
        }
      }
    };

    const onDragOver = (clientX: number, clientY: number, offsetX: number) => {
      if (editorContext && layout.value && editorContext.isDragging.value) {
        const rect = layout.value.getBoundingClientRect();
        const x = clientX - rect.left;
        const y = clientY - rect.top;
        const baseLayoutIndex = 2;
        const inBaseLayout = path.value.length <= baseLayoutIndex;
        const safeAreaSize = 30;
        const insideSafeArea =
          x <= safeAreaSize || x >= rect.width - safeAreaSize;

        if (!node.value.children?.length && (!insideSafeArea || inBaseLayout)) {
          setHoverStates("layout", "inside", node.value);
          setHoverRegion(region.value);
          setHoverPath(path.value);
          return;
        }

        const index = (node.value.children ?? []).findIndex(
          c =>
            x >= c.left &&
            x <= c.left + c.width &&
            y >= c.top &&
            y <= c.top + c.height
        );

        const control =
          index !== -1 && node.value.children?.length
            ? node.value.children[index]
            : null;

        if (!control && !insideSafeArea) {
          setHoverStatesForClosestControl(x, y);
        } else if (control && (!insideSafeArea || inBaseLayout)) {
          if (control.kind === "Section" || control.kind === "Layout") {
            return;
          }

          if (offsetX < control.width / 2) {
            setHoverStates("control", "before", control, index);
            setHoverRegion(region.value);
            setHoverPath(path.value);
          } else {
            setHoverStates("control", "after", control, index);
            setHoverRegion(region.value);
            setHoverPath(path.value);
          }
        } else if (!inBaseLayout && insideSafeArea) {
          setEdgeHoverStates(x, safeAreaSize, rect.width);
        }
      }
    };

    const onRemove = () => {
      if (!editorContext || !path.value.length) return;

      editorContext.removeNode(region.value, path.value);
    };

    const allParents = computed(() => [node.value, ...parents.value]);

    return {
      allParents,
      hoverBox,
      layout,
      style,
      selectNode,
      addControl,
      onDragEnter,
      onDragOver,
      isSelected,
      canBeRemoved,
      checkmarkChecked,
      onRemove
    };
  }
});
