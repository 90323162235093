
import { defineComponent, ref } from "vue";
import { Switch } from "@headlessui/vue";

export default defineComponent({
  components: {
    Switch
  },
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  setup(props, { emit }) {
    const enabled = ref(props.modelValue);

    return {
      enabled,
      toggle: (v: boolean) => emit("update:modelValue", v)
    };
  }
});
