import { DirectiveBinding } from "vue";

const autofocus = {
  mounted: (el: HTMLElement, binding: DirectiveBinding) => {
    binding.value && el.focus();
  }
};

export default {
  autofocus
};
