import { ApiResponse, apiClient } from "./client";

export const getInvitations =
  (organizationId: number) => async (): Promise<ApiResponse<Invitation[]>> => {
    const response = await apiClient.get(
      `/invitations?organizationId=${organizationId}`
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: response.data as Invitation[]
      };
    }
  };

export const getPendingInvitations =
  () => async (): Promise<ApiResponse<Invitation[]>> => {
    const response = await apiClient.get(`/invitations/pending`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: response.data as Invitation[]
      };
    }
  };

export const getInvitation =
  (token: string) => async (): Promise<ApiResponse<SparseInvitation>> => {
    const response = await apiClient.get(`/invitations/${token}`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: response.data as SparseInvitation
      };
    }
  };

export const createInvitation =
  (email: string, organizationId: number) =>
  async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.post(`/invitations`, {
      data: { email, organizationId }
    });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export const requestInvitation =
  (id: number) => async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.post(`/invitations/request/${id}`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export const cancelInvitation =
  (id: number) => async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.delete(`/invitations/${id}`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export const acceptInvitation =
  (token: string) => async (): Promise<ApiResponse<AcceptInvitation>> => {
    const response = await apiClient.post(`/invitations/${token}`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as AcceptInvitation };
    }
  };

export enum InvitationStatus {
  Sent = 0,
  Accepted = 1,
  Cancelled = 2,
  Requested = 3
}

export type Invitation = {
  id: number;
  email: string;
  created: Date;
  status: InvitationStatus;
  organizationId: number;
  organizationName: string;
  token: string;
};

export type SparseInvitation = {
  email: string;
  organizationName: string;
  userExists: boolean;
};

export type AcceptInvitation = {
  organizationId: number | null;
  organizationName: string | null;
  isForCurrentUser: boolean;
  customerId: number | null;
};
