
import { v4 as uuidv4 } from "uuid";
import { ComputedNode } from "@/admin/lib/templateMapper";
import { Field } from "@/api/caseApi";
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  PropType,
  ref,
  watch
} from "vue";
import { controlledComputed, controlledRef } from "@vueuse/core";
import debounce from "lodash/debounce";
import {
  AddEntityFieldKey,
  AddValueFieldKey,
  EntityFieldsKey,
  RenameAppendixKey,
  ValueFieldsKey
} from "./types";
import { CaseTextFieldValues } from "@/api/types";

export default defineComponent({
  props: {
    node: { type: Object as PropType<ComputedNode>, required: true },
    caseReadOnly: {
      type: Boolean
    },
    showValidationFailures: {
      type: Boolean,
      default: false
    },
    nodeWidth: { type: Number as PropType<number>, required: false }
  },
  setup(props) {
    const id = uuidv4();
    const nodeId = props.node.values?.id?.toString() ?? "";
    const controlsAppendixName =
      props.node.values?.controlsAppendixName ?? false;
    const entityNameId = computed(() =>
      props.node.values?.entityNameId
        ? (props.node.values.entityNameId as number)
        : undefined
    );

    const clientTextScaling: number = inject("clientTextScaling") ?? 1;

    const valueFields = inject(ValueFieldsKey);
    const valueField = controlledComputed(
      () => !!valueFields?.value[nodeId],
      () => valueFields?.value[nodeId]
    );

    watch(
      () => (valueField.value?.values as CaseTextFieldValues).text,
      () =>
        (text.value = (valueField.value?.values as CaseTextFieldValues).text)
    );

    const entityFields = inject(EntityFieldsKey);
    const entityField = computed(() =>
      entityNameId.value === undefined || !entityFields
        ? undefined
        : entityFields.value[entityNameId.value]
    );

    const addEntityField = inject(AddEntityFieldKey, () => void 0);
    const addValueField = inject(AddValueFieldKey, () => void 0);
    const renameAppendix = inject(RenameAppendixKey, () => void 0);

    const field = computed<Field>(
      () =>
        (entityNameId.value ? entityField.value : valueField.value) ?? {
          readOnly: false,
          valueKind: "TextField",
          values: {
            text: (props.node?.values?.defaultValue as string) || "",
            valueKind: "TextField"
          }
        }
    );

    const onInputChanged = (newValue: string) => {
      if (entityNameId.value) {
        if (!entityField.value) {
          addEntityField({
            entityNameId: entityNameId.value,
            readOnly: false,
            values: {
              text: newValue,
              valueKind: "TextField"
            },
            valueKind: "TextField"
          });
        } else if (entityField.value.values.valueKind === "TextField") {
          entityField.value.values.text = newValue;
        }
      } else {
        if (!valueField.value) {
          addValueField({
            name: nodeId,
            readOnly: false,
            values: {
              text: newValue,
              valueKind: "TextField"
            },
            valueKind: "TextField"
          });
        } else if (valueField.value.values.valueKind == "TextField") {
          valueField.value.values.text = newValue;
        }
        if (controlsAppendixName) {
          renameAppendix(newValue);
        }
      }
    };

    const textareaRef = ref<undefined | HTMLTextAreaElement>(undefined);
    const initialHeight = ref<undefined | number>(undefined);

    onMounted(() => {
      initialHeight.value = textareaRef.value?.scrollHeight;
    });

    const text = controlledRef(
      field.value.values.valueKind === "TextField"
        ? field.value.values.text
        : "",
      {
        onBeforeChange(_, oldValue) {
          if (
            textareaRef.value !== undefined &&
            initialHeight.value !== undefined &&
            textareaRef.value.scrollHeight > initialHeight.value
          ) {
            textareaRef.value.value = oldValue;

            return false;
          }
        },
        onChanged: debounce(onInputChanged, 250)
      }
    );

    return {
      id,
      field,
      textareaRef,
      text,
      clientTextScaling
    };
  }
});
