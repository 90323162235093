import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "whitespace-nowrap bg-control-gray-tint" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "flex flex-col w-full",
    style: _normalizeStyle({ height: _ctx.containerHeight + 'px' })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        ref: "markdownContainer",
        class: "font-sans text-2xs whitespace-pre-wrap",
        innerHTML: _ctx.compiledMarkdown
      }, null, 8, _hoisted_2)
    ])
  ], 4))
}