import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Switch = _resolveComponent("Switch", true)!

  return (_openBlock(), _createBlock(_component_Switch, {
    modelValue: _ctx.enabled,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.enabled) = $event)),
      _ctx.toggle
    ],
    class: _normalizeClass([[
      _ctx.enabled ? 'bg-fa-orange' : 'bg-gray-200',
      _ctx.disabled ? 'cursor-not-allowed' : 'cursor-pointer'
    ], "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-fa-orange"]),
    disabled: _ctx.disabled
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", {
        "aria-hidden": "true",
        class: _normalizeClass([[_ctx.enabled ? 'translate-x-5' : 'translate-x-0'], "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 ]"])
      }, null, 2)
    ]),
    _: 1
  }, 8, ["modelValue", "onUpdate:modelValue", "class", "disabled"]))
}