import { useToast as useToastification } from "vue-toastification";
import BaseToast from "@/components/BaseToast.vue";

export function useToast() {
  const toastification = useToastification();

  const toast = {
    success(title: string, text?: string) {
      toastification({
        component: BaseToast,
        props: {
          title: title,
          text: text,
          type: "success"
        }
      });
    },
    info(title: string, text?: string) {
      toastification({
        component: BaseToast,
        props: {
          title: title,
          text: text,
          type: "info"
        }
      });
    },
    error(title: string, text?: string) {
      toastification({
        component: BaseToast,
        props: {
          title: title,
          text: text,
          type: "error"
        }
      });
    }
  };

  return toast;
}
