import { useSession } from "@/use/session";
import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  RouteRecordRaw
} from "vue-router";

export const routeNames = {
  internal: "internal",
  external: "external",
  login: "login",
  logout: "logout",
  account: "account",
  accountDetails: "account-details",
  adminOrganizationManage: "admin-organization-manage",
  adminOrganizationEdit: "admin-organization-edit",
  adminOrganizationEditDetails: "admin-organization-edit-details",
  adminOrganizationsList: "admin-organizations-list",
  adminOrganizationInvites: "admin-organization-invites",
  adminOrganizationUsers: "admin-organization-users",
  adminOrganizationUserDetails: "admin-organization-user-details",
  adminOrganizationUsageStats: "admin-organization-usage-stats",
  adminPurchases: "admin-purchases",
  adminPurchasesInvoiceList: "admin-purchases-invoice-list",
  adminPurchasesCardPaymentsList: "admin-purchases-card-payments-list",
  adminPurchasesSubscriptionsList: "admin-purchases-subscriptions-list",
  adminSignings: "admin-signings",
  adminSigningsManage: "admin-signings-manage",
  adminStats: "admin-stats",
  adminStatsDashboard: "admin-stats-dashboard",
  adminStatsTemplatesDetails: "admin-stats-templates-details",
  adminTemplatesList: "admin-templates-list",
  adminTemplateDetails: "admin-template-details",
  adminUsersEdit: "admin-users-edit",
  adminUsersEditDetails: "admin-users-edit-details",
  adminUsersEditOrganisations: "admin-users-edit-organizations",
  adminUsersManage: "admin-users-manage",
  adminUsersList: "admin-users-list",
  changeEmail: "change-email",
  changePassword: "change-password",
  confirmEmailSent: "confirm-email-sent",
  confirmEmail: "confirm-email",
  contractCasesList: "contract-cases-list",
  contractCaseTemplateList: "contract-case-template-list",
  contractEdit: "contract-edit",
  createOrganization: "create-organization",
  createTemplateFomVersion: "create-template-from-version",
  customer: "customer",
  customerAdmins: "customer-admins",
  customerStatsTemplates: "customer-stats-templates",
  customerApiKeys: "customer-api-keys",
  customerDetails: "customer-details",
  customerDelete: "customer-delete",
  customerOrganizationList: "customer-organization-list",
  customerSubscription: "customer-subscription",
  externalContractEdit: "external-contract-edit",
  externalPaymentReciept: "external-payment-reciept",
  externalAnonymousContractEdit: "external-anonymous-contract-edit",
  externalErrorInvalidCaseUrl: "external-error-invalid-case-url",
  forgotPassword: "forgot-password",
  organization: "organization",
  organizationDetails: "organization-details",
  organizationInvitationList: "organization-invitation-list",
  organizationDelete: "organization-delete",
  organizationUserDetails: "organization-user-details",
  organizationUserList: "organization-user-list",
  organizationStatsTemplates: "organization-stats-templates",
  register: "register",
  deleteAccount: "delete-account",
  resetPassword: "reset-password",
  template: "templates",
  templateList: "templates-list",
  templatesEdit: "templates-edit",
  templatesPreview: "templates-preview",
  templatesNew: "templates-new",
  unauthorized: "Unauthorized",
  uploadLogo: "upload-logo",
  users: "users",
  userTemplatedetails: "user-template-details",
  admin: {
    index: "admin",
    login: "admin-login",
    customer: {
      edit: "admin-customer-edit",
      editDetails: "admin-customer-edit-details",
      editAdmins: "admin-customer-edit-admins",
      editOrganizations: "admin-customer-edit-organizations",
      editSubscriptions: "admin-customer-edit-subscription",
      list: "admin-customers-list",
      comments: "admin-customer-comments",
      manage: "admin-customer-manage",
      usageStats: "admin-customer-usage-stats"
    },
    reports: {
      index: "admin-reports-index",
      templates: "admin-reports-templates",
      templateDetails: "admin-reports-template-details",
      userOrganisations: "admin-reports-user-organisations",
      userCustomerContacts: "admin-reports-user-customer-contacts"
    }
  }
};

const routes: RouteRecordRaw[] = [
  {
    path: "/external",
    name: routeNames.external,
    component: () => import("../external/pages/ExternalPage.vue"),
    children: [
      {
        path: "/external/contract-create/:id",
        name: routeNames.externalAnonymousContractEdit,
        component: () =>
          import(
            "../external/pages/anonymous-contract-edit/EditContractPage.vue"
          )
      },
      {
        path: "/external/contract-cases/:id",
        name: routeNames.externalContractEdit,
        component: () =>
          import("../external/pages/contract-cases/EditContractPage.vue")
      },
      {
        path: "/external/payment/:id",
        name: routeNames.externalPaymentReciept,
        component: () => import("../external/pages/customer/PaymentReciept.vue")
      },
      {
        path: "/external/error/invalid-case-url",
        name: routeNames.externalErrorInvalidCaseUrl,
        component: () => import("../external/pages/error/InvalidCaseUrl.vue")
      }
    ]
  },
  {
    path: "/admin",
    component: () => import("../admin/pages/AdminPage.vue"),
    name: routeNames.admin.index,
    redirect: { name: routeNames.adminTemplatesList },
    children: [
      {
        path: "/admin/stats",
        name: routeNames.adminStats,
        meta: { requiresAuth: true, roles: ["SysAdmin"] },
        component: () => import("../admin/pages/stats/StatsPage.vue"),
        children: [
          {
            path: "dashboard",
            name: routeNames.adminStatsDashboard,
            meta: { requiresAuth: true, roles: ["SysAdmin"] },
            component: () =>
              import("../admin/pages/stats/StatsDashboardPage.vue")
          }
        ]
      },
      {
        path: "/admin/reports",
        name: routeNames.admin.reports.index,
        meta: { requiresAuth: true, roles: ["SysAdmin"] },
        component: () => import("../admin/pages/reports/ReportsIndexPage.vue")
      },
      {
        path: "/admin/reports/templates",
        name: routeNames.admin.reports.templates,
        meta: { requiresAuth: true, roles: ["SysAdmin"] },
        component: () =>
          import("../admin/pages/reports/ReportsTemplatesPage.vue")
      },
      {
        path: "/admin/reports/templates/:id",
        name: routeNames.admin.reports.templateDetails,
        meta: { requiresAuth: true, roles: ["SysAdmin"] },
        component: () =>
          import("../admin/pages/reports/ReportsTemplateDetailsPage.vue")
      },
      {
        path: "/admin/reports/user-organisations",
        name: routeNames.admin.reports.userOrganisations,
        meta: { requiresAuth: true, roles: ["SysAdmin"] },
        component: () =>
          import("../admin/pages/reports/ReportsUserOrganisations.vue")
      },
      {
        path: "/admin/reports/customer-contacts",
        name: routeNames.admin.reports.userCustomerContacts,
        meta: { requiresAuth: true, roles: ["SysAdmin"] },
        component: () =>
          import("../admin/pages/reports/ReportsCustomerContacts.vue")
      },
      {
        path: "/admin/purchases",
        name: routeNames.adminPurchases,
        meta: { requiresAuth: true, roles: ["SysAdmin", "SupportAdmin"] },
        component: () => import("../admin/pages/purchases/PurchasesPage.vue"),
        children: [
          {
            path: "invoices",
            name: routeNames.adminPurchasesInvoiceList,
            meta: { requiresAuth: true, roles: ["SysAdmin", "SupportAdmin"] },
            component: () =>
              import("../admin/pages/purchases/PurchasesInvoicesList.vue")
          },
          {
            path: "cardpayments",
            name: routeNames.adminPurchasesCardPaymentsList,
            meta: { requiresAuth: true, roles: ["SysAdmin", "SupportAdmin"] },
            component: () =>
              import("../admin/pages/purchases/PurchasesCardPaymentsList.vue")
          },
          {
            path: "subscriptions",
            name: routeNames.adminPurchasesSubscriptionsList,
            meta: { requiresAuth: true, roles: ["SysAdmin", "SupportAdmin"] },
            component: () =>
              import("../admin/pages/purchases/PurchasesSubscriptionsList.vue")
          }
        ]
      },
      {
        path: "/admin/users",
        name: routeNames.adminUsersList,
        meta: { requiresAuth: true, roles: ["SysAdmin", "SupportAdmin"] },
        component: () => import("../admin/pages/users/ListUsersPage.vue")
      },
      {
        path: "/admin/users/:id",
        name: routeNames.adminUsersEdit,
        meta: { requiresAuth: true, roles: ["SysAdmin", "SupportAdmin"] },
        component: () => import("../admin/pages/users/UserPage.vue"),
        children: [
          {
            path: "details",
            name: routeNames.adminUsersEditDetails,
            meta: { requiresAuth: true, roles: ["SysAdmin", "SupportAdmin"] },
            component: () => import("../admin/pages/users/UserDetailsTab.vue")
          },
          {
            path: "organisations",
            name: routeNames.adminUsersEditOrganisations,
            meta: { requiresAuth: true, roles: ["SysAdmin", "SupportAdmin"] },
            component: () =>
              import("../admin/pages/users/UserOrganisationsTab.vue")
          },
          {
            path: "manage",
            name: routeNames.adminUsersManage,
            meta: { requiresAuth: true, roles: ["SysAdmin", "SupportAdmin"] },
            component: () => import("../admin/pages/users/UserManageTab.vue")
          }
        ]
      },
      {
        path: "/admin/customers",
        name: routeNames.admin.customer.list,
        meta: { requiresAuth: true, roles: ["SysAdmin", "SupportAdmin"] },
        component: () =>
          import("../admin/pages/customers/ListCustomersPage.vue")
      },
      {
        path: "/admin/customers/:id",
        name: routeNames.admin.customer.edit,
        meta: { requiresAuth: true, roles: ["SysAdmin", "SupportAdmin"] },
        component: () => import("../admin/pages/customers/CustomerPage.vue"),
        children: [
          {
            path: "details",
            name: routeNames.admin.customer.editDetails,
            meta: { requiresAuth: true, roles: ["SysAdmin", "SupportAdmin"] },
            component: () =>
              import("../admin/pages/customers/CustomerDetailsTab.vue")
          },
          {
            path: "organizations",
            name: routeNames.admin.customer.editOrganizations,
            meta: { requiresAuth: true, roles: ["SysAdmin", "SupportAdmin"] },
            component: () =>
              import("../admin/pages/customers/CustomerOrganizationListTab.vue")
          },
          {
            path: "subscription",
            name: routeNames.admin.customer.editSubscriptions,
            meta: { requiresAuth: true, roles: ["SysAdmin", "SupportAdmin"] },
            component: () =>
              import("../admin/pages/customers/CustomerSubscriptionTab.vue")
          },
          {
            path: "admins",
            name: routeNames.admin.customer.editAdmins,
            meta: { requiresAuth: true, roles: ["SysAdmin", "SupportAdmin"] },
            component: () =>
              import("../admin/pages/customers/CustomerAdminsTab.vue")
          },
          {
            path: "comments",
            name: routeNames.admin.customer.comments,
            meta: { requiresAuth: true, roles: ["SysAdmin", "SupportAdmin"] },
            component: () =>
              import("../admin/pages/customers/CustomerCommentsTab.vue")
          },
          {
            path: "manage",
            name: routeNames.admin.customer.manage,
            meta: { requiresAuth: true, roles: ["SysAdmin", "SupportAdmin"] },
            component: () =>
              import("../admin/pages/customers/CustomerManageTab.vue")
          },
          {
            path: "stats",
            name: routeNames.admin.customer.usageStats,
            meta: { requiresAuth: true, roles: ["SysAdmin", "SupportAdmin"] },
            component: () =>
              import("../admin/pages/customers/CustomerUsageStats.vue")
          }
        ]
      },
      {
        path: "/admin/organizations",
        name: routeNames.adminOrganizationsList,
        meta: { requiresAuth: true, roles: ["SysAdmin", "SupportAdmin"] },
        component: () =>
          import("../admin/pages/organizations/ListOrganizationsPage.vue")
      },
      {
        path: "/admin/organizations/:id",
        name: routeNames.adminOrganizationEdit,
        meta: { requiresAuth: true, roles: ["SysAdmin", "SupportAdmin"] },
        component: () =>
          import("../admin/pages/organizations/OrganizationPage.vue"),
        children: [
          {
            path: "details",
            name: routeNames.adminOrganizationEditDetails,
            meta: { requiresAuth: true, roles: ["SysAdmin", "SupportAdmin"] },
            component: () =>
              import("../admin/pages/organizations/OrganizationDetailsTab.vue")
          },
          {
            path: "stats",
            name: routeNames.adminOrganizationUsageStats,
            meta: { requiresAuth: true, roles: ["SysAdmin", "SupportAdmin"] },
            component: () =>
              import("../admin/pages/organizations/OrganisationUsageStats.vue")
          },
          {
            path: "users",
            name: routeNames.adminOrganizationUsers,
            meta: { requiresAuth: true, roles: ["SysAdmin", "SupportAdmin"] },
            component: () =>
              import("../admin/pages/organizations/OrganizationUsersTab.vue"),
            children: [
              {
                path: ":userId",
                name: routeNames.adminOrganizationUserDetails,
                component: () =>
                  import(
                    "../admin/components/AdminOrganizationUserContainer.vue"
                  ),
                meta: {
                  requiresAuth: true,
                  roles: ["SysAdmin", "SupportAdmin"]
                }
              }
            ]
          },
          {
            path: "invites",
            name: routeNames.adminOrganizationInvites,
            meta: { requiresAuth: true, roles: ["SysAdmin", "SupportAdmin"] },
            component: () =>
              import("../admin/pages/organizations/OrganizationInvitesTab.vue")
          },
          {
            path: "manage",
            name: routeNames.adminOrganizationManage,
            meta: { requiresAuth: true, roles: ["SysAdmin", "SupportAdmin"] },
            component: () =>
              import("../admin/pages/organizations/OrganizationManageTab.vue")
          }
        ]
      },
      {
        path: "/admin/templates",
        name: routeNames.adminTemplatesList,
        meta: { requiresAuth: true, roles: ["SysAdmin"] },
        component: () =>
          import("../admin/pages/templates/ListTemplatesPage.vue")
      },
      {
        path: "/admin/templates/:id/details",
        name: routeNames.adminTemplateDetails,
        meta: { requiresAuth: true, roles: ["SysAdmin"] },
        component: () =>
          import("../admin/pages/templates/TemplateVersionList.vue"),
        children: [
          {
            path: "copy/:versionId",
            name: routeNames.createTemplateFomVersion,
            component: () =>
              import("../admin/components/CreateTemplateFromVersionModal.vue"),
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        path: "/admin/templates/new",
        name: routeNames.templatesNew,
        meta: { requiresAuth: true, roles: ["SysAdmin"] },
        component: () => import("../admin/pages/templates/NewTemplatePage.vue")
      },
      {
        path: "/admin/templates/:id/versions/:versionId/edit",
        name: routeNames.templatesEdit,
        meta: { requiresAuth: true, roles: ["SysAdmin"] },
        component: () => import("../admin/pages/templates/EditTemplatePage.vue")
      },
      {
        path: "/admin/templates/:id/versions/:versionId/preview",
        name: routeNames.templatesPreview,
        meta: { requiresAuth: true, roles: ["SysAdmin"] },
        component: () =>
          import("../admin/pages/templates/PreviewTemplatePage.vue")
      },
      {
        path: "/admin/signings",
        name: routeNames.adminSignings,
        meta: { requiresAuth: true, roles: ["SysAdmin"] },
        component: () => import("../admin/pages/signing/SigningsPage.vue"),
        children: [
          {
            path: "manage",
            name: routeNames.adminSigningsManage,
            component: () =>
              import("../admin/pages/signing/ManageSigningsTab.vue"),
            meta: { requiresAuth: true }
          }
        ]
      }
    ]
  },
  {
    path: "/",
    name: routeNames.internal,
    component: () => import("../internal/pages/InternalPage.vue"),
    children: [
      {
        path: "/unauthorized",
        name: routeNames.unauthorized,
        component: () => import("../internal/pages/UnauthorizedPage.vue")
      },
      {
        path: "/logout",
        name: routeNames.logout,
        component: () => import("../internal/pages/LogoutPage.vue")
      },
      {
        path: "/templates",
        name: routeNames.template,
        component: () =>
          import("../internal/pages/templates/TemplatesPage.vue"),
        children: [
          {
            path: "contract-templates",
            name: routeNames.contractCaseTemplateList,
            component: () =>
              import("../internal/pages/templates/ListCaseTemplatesPage.vue")
          },
          {
            path: "",
            name: routeNames.templateList,
            component: () =>
              import("../internal/pages/templates/ListTemplatesPage.vue"),
            children: [
              {
                path: ":id/details",
                name: routeNames.userTemplatedetails,
                component: () =>
                  import("../internal/components/StartContractCaseModal.vue")
              },
              {
                path: "/register",
                name: routeNames.register,
                component: () => import("../internal/pages/RegisterModal.vue")
              },
              {
                path: "/login",
                name: routeNames.login,
                component: () => import("../internal/pages/LoginModal.vue")
              },
              {
                path: "/confirmemailsent",
                name: routeNames.confirmEmailSent,
                component: () =>
                  import("../internal/pages/ConfirmEmailSentModal.vue")
              },
              {
                path: "/confirmemail",
                name: routeNames.confirmEmail,
                component: () =>
                  import("../internal/pages/ConfirmEmailModal.vue")
              }
            ]
          }
        ]
      },
      {
        path: "/contract-cases",
        name: routeNames.contractCasesList,
        component: () =>
          import("../internal/pages/contract-cases/ListContractCasesPage.vue"),
        meta: { requiresAuth: true }
      },

      {
        path: "/contract-cases/:id",
        name: routeNames.contractEdit,
        meta: { requiresAuth: true },
        component: () =>
          import("../internal/pages/contract-cases/EditContractPage.vue")
      },
      {
        path: "/create-organization",
        name: routeNames.createOrganization,
        component: () => import("../internal/pages/CreateOrganizationPage.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/organization",
        name: routeNames.organization,
        component: () =>
          import("../internal/pages/organization/OrganizationPage.vue"),
        meta: { requiresAuth: true },
        redirect: { name: routeNames.organizationUserList },
        children: [
          {
            path: "details",
            name: routeNames.organizationDetails,
            component: () =>
              import(
                "../internal/pages/organization/OrganizationDetailsPage.vue"
              ),
            meta: { requiresAuth: true },
            children: [
              {
                path: "upload",
                name: routeNames.uploadLogo,
                component: () =>
                  import("../internal/pages/organization/FileUpload.vue"),
                meta: { requiresAuth: true }
              }
            ]
          },
          {
            path: "users",
            name: routeNames.organizationUserList,
            meta: { requiresAuth: true },
            component: () =>
              import(
                "../internal/pages/organization/OrganizationUserListPage.vue"
              ),
            children: [
              {
                path: ":id",
                name: routeNames.organizationUserDetails,
                component: () =>
                  import("../internal/components/users/EditUserContainer.vue"),
                meta: { requiresAuth: true }
              }
            ]
          },
          {
            path: "invitations",
            name: routeNames.organizationInvitationList,
            meta: { requiresAuth: true },
            component: () =>
              import(
                "../internal/pages/organization/OrganizationInvitationListPage.vue"
              )
          },
          {
            path: "delete",
            name: routeNames.organizationDelete,
            meta: { requiresAuth: true },
            component: () =>
              import(
                "../internal/pages/organization/OrganizationDeletePage.vue"
              )
          },
          {
            path: "stats",
            name: routeNames.organizationStatsTemplates,
            meta: { requiresAuth: true },
            component: () =>
              import(
                "../internal/pages/organization/OrganisationStatsTemplatesPage.vue"
              )
          }
        ]
      },
      {
        path: "/account",
        name: routeNames.account,
        component: () => import("../internal/pages/account/AccountPage.vue"),
        meta: { requiresAuth: true },
        redirect: { name: routeNames.accountDetails },
        children: [
          {
            path: "details",
            name: routeNames.accountDetails,
            component: () =>
              import("../internal/pages/account/AccountDetailsPage.vue"),
            meta: { requiresAuth: true }
          },
          {
            path: "change-password",
            name: routeNames.changePassword,
            meta: { requiresAuth: true },
            component: () =>
              import("../internal/pages/account/ChangePasswordPage.vue")
          },
          {
            path: "change-email",
            name: routeNames.changeEmail,
            meta: { requiresAuth: true },
            component: () =>
              import("../internal/pages/account/ChangeEmailPage.vue")
          },
          {
            path: "delete-account",
            name: routeNames.deleteAccount,
            meta: { requiresAuth: true },
            component: () =>
              import("../internal/pages/account/DeleteAccountPage.vue")
          }
        ]
      },
      {
        path: "/customer",
        name: routeNames.customer,
        component: () => import("../internal/pages/customer/CustomerPage.vue"),
        meta: { requiresAuth: true },
        redirect: { name: routeNames.customerDetails },
        children: [
          {
            path: "organizations",
            name: routeNames.customerOrganizationList,
            meta: { requiresAuth: true },
            component: () =>
              import(
                "../internal/pages/customer/CustomerOrganizationListPage.vue"
              )
          },
          {
            path: "details",
            name: routeNames.customerDetails,
            meta: { requiresAuth: true },
            component: () =>
              import("../internal/pages/customer/CustomerDetailsPage.vue")
          },
          {
            path: "keys",
            name: routeNames.customerApiKeys,
            meta: { requiresAuth: true },
            component: () =>
              import("../internal/pages/customer/CustomerApiKeysPage.vue")
          },
          {
            path: "subscription",
            name: routeNames.customerSubscription,
            meta: { requiresAuth: true },
            component: () =>
              import("../internal/pages/customer/CustomerSubscriptionPage.vue")
          },
          {
            path: "admins",
            name: routeNames.customerAdmins,
            meta: { requiresAuth: true },
            component: () =>
              import("../internal/pages/customer/CustomerAdminsPage.vue")
          },
          {
            path: "delete",
            name: routeNames.customerDelete,
            meta: { requiresAuth: true },
            component: () =>
              import("../internal/pages/customer/CustomerDeletePage.vue")
          },
          {
            path: "stats-templates",
            name: routeNames.customerStatsTemplates,
            meta: { requiresAuth: true },
            component: () =>
              import(
                "../internal/pages/customer/CustomerStatsTemplatesPage.vue"
              )
          }
        ]
      },
      {
        path: "/invitations/:token",
        component: () =>
          import("../internal/pages/invitations/InvitationPage.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/forgot-password",
        name: routeNames.forgotPassword,
        component: () =>
          import("../internal/pages/password-reset/ForgotPasswordPage.vue")
      },
      {
        path: "/reset-password/:token",
        name: routeNames.resetPassword,
        component: () =>
          import("../internal/pages/password-reset/ResetPasswordPage.vue")
      }
    ]
  }
];

const router = createRouter({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
  history: createWebHistory(process.env.BASE_URL),
  routes
});

const { currentRole, isSysAdmin, isSupportAdmin, isLoggedIn } = useSession();

type MetaWithRoles = { roles: string[] };
function isMetaWithRoles(meta?: unknown): meta is MetaWithRoles {
  return !!meta && !!(meta as MetaWithRoles).roles;
}

function checkRoles(to: RouteLocationNormalized): boolean {
  if (isSysAdmin.value) return true;

  if (isMetaWithRoles(to.meta)) {
    const requiredRoles = to.meta.roles;
    if (requiredRoles.includes("SupportAdmin")) return isSupportAdmin.value;
    if (requiredRoles.includes("SysAdmin")) return false;

    if (!currentRole.value) return false;

    if (requiredRoles.includes("Owner")) return currentRole.value === "Owner";
    if (requiredRoles.includes("Admin"))
      return ["Owner", "Admin"].includes(currentRole.value);
    if (requiredRoles.includes("User")) return currentRole.value === "User";
  }

  return true;
}

router.beforeEach((to, from, next) => {
  if (
    (from.name == routeNames.login || from.name == routeNames.register) &&
    typeof from.query.ReturnUrl === "string" &&
    from.query.ReturnUrl
  ) {
    if (
      to.name != routeNames.login &&
      to.name != routeNames.register &&
      to.name != routeNames.confirmEmailSent &&
      to.name != routeNames.confirmEmail &&
      to.name != routeNames.adminTemplatesList &&
      from.query.ReturnUrl.split(/[?#]/)[0] != to.fullPath
    ) {
      next({ path: from.query.ReturnUrl });
      return;
    }
  }

  if (to.fullPath === "/") {
    if (isSysAdmin.value) {
      next({ name: routeNames.adminTemplatesList });
    } else if (isSupportAdmin.value) {
      next({ name: routeNames.admin.customer.list });
    } else {
      next({ name: routeNames.templateList });
    }
    return;
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isLoggedIn.value) {
      next({ name: routeNames.login, query: { ReturnUrl: to.fullPath } });
    } else {
      if (checkRoles(to)) {
        next();
      } else {
        next({
          path: "/unauthorized"
        });
      }
    }
  } else {
    if (checkRoles(to)) {
      next();
    } else {
      next({
        path: "/unauthorized"
      });
    }
  }
});

router.onError((error: Error) => {
  try {
    const reloaded = window.localStorage.getItem("reloaded");
    window.localStorage.removeItem("reloaded");
    if (/loading chunk \d* failed./i.test(error.message) && navigator.onLine) {
      const now = new Date();
      if (
        !reloaded ||
        now.getTime() - new Date(parseInt(reloaded, 10)).getTime() > 60 * 1000 // Ensure we can auto-reload again 1 minute after a successful load
      ) {
        window.localStorage.setItem("reloaded", now.getTime().toString());
        window.location.reload();
      }
    }
  } catch {
    /* no-op */
  }
});

export default router;
