import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col w-full justify-center" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["disabled", "name", "id"]
const _hoisted_4 = ["value", "selected"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'flex',
      'w-full',
      'h-full',
      (_ctx.field && _ctx.field.readOnly) || _ctx.caseReadOnly
        ? 'bg-fa-turquoise bg-opacity-20'
        : 'bg-control-gray-tint'
    ])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.node.values.hasLabel)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            for: _ctx.node.values.id,
            class: "text-3xs leading-4 overflow-hidden whitespace-nowrap flex-shrink-0 text-gray-800 pl-[3px]"
          }, _toDisplayString(_ctx.node.values.label), 9, _hoisted_2))
        : _createCommentVNode("", true),
      _createElementVNode("select", {
        disabled: (_ctx.field && _ctx.field.readOnly) || _ctx.caseReadOnly,
        class: _normalizeClass([
          'bg-transparent',
          'w-full',
          'text-3xs',
          'border-none',
          'p-[3px]',
          'focus:ring-fa-blue focus:border-fa-blue',
          ((_ctx.field && _ctx.field.readOnly) || _ctx.caseReadOnly) && 'opacity-100'
        ]),
        name: _ctx.node.values.id,
        id: _ctx.node.values.id,
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values, (val, key) => {
          return (_openBlock(), _createElementBlock("option", {
            key: key,
            value: val.value,
            selected: _ctx.selectedValue === val.value
          }, _toDisplayString(val.text), 9, _hoisted_4))
        }), 128))
      ], 42, _hoisted_3)
    ])
  ], 2))
}