
import { defineComponent, computed, toRefs, watch, onMounted, ref } from "vue";
import { renderMarkdown } from "@/utils/markdownHelpers";

export default defineComponent({
  name: "CustomText",
  props: {
    defaultValue: { type: String, required: false }
  },
  setup(props) {
    const { defaultValue } = toRefs(props);
    const markdownContainer = ref<HTMLElement | null>(null);
    const containerHeight = ref(0);

    const compiledMarkdown = computed(() =>
      renderMarkdown(defaultValue.value?.replaceAll("\n", "<br>") || "")
    );

    const calculateHeight = () => {
      if (markdownContainer.value) {
        containerHeight.value = markdownContainer.value.scrollHeight;
      }
    };

    onMounted(() => {
      calculateHeight();
    });

    watch(compiledMarkdown, () => {
      calculateHeight();
    });

    return {
      markdownContainer,
      containerHeight,
      compiledMarkdown
    };
  }
});
