import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col w-full h-full bg-control-gray-tint px-1.5 py-1.5 overflow-hidden" }
const _hoisted_2 = {
  key: 0,
  class: "text-3xs leading-4 flex-shrink-0 text-gray-800 whitespace-nowrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hasLabel)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.labelName), 1))
      : _createCommentVNode("", true),
    _createElementVNode("pre", {
      style: _normalizeStyle(_ctx.hasLabel ? '' : { fontSize: '0.7rem', lineHeight: '1rem' }),
      class: _normalizeClass(["font-sans text-2xs whitespace-pre-wrap", { 'text-gray-500': !_ctx.defaultValue }])
    }, _toDisplayString(_ctx.defaultValue || _ctx.placeholder), 7)
  ]))
}