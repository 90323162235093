import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-3xs leading-4 flex-shrink-0 text-gray-800 whitespace-nowrap"
}
const _hoisted_2 = ["disabled", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'flex flex-col',
      'w-full',
      'h-full',
      _ctx.caseReadOnly || _ctx.appendixNumber !== 0
        ? 'bg-fa-turquoise bg-opacity-20'
        : 'bg-control-gray-tint',
      'px-1.5',
      'box-border',
      'overflow-hidden',
      !_ctx.hasLabel && 'justify-center'
    ])
  }, [
    (_ctx.hasLabel)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      disabled: _ctx.caseReadOnly || _ctx.appendixNumber !== 0,
      type: "text",
      class: "bg-transparent text-2xs py-0 px-[3px] border-none focus:ring-fa-blue focus:border-fa-blue",
      value: _ctx.baseTemplateSortOrderOverride || _ctx.appendixNumber,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onInputChanged($event)))
    }, null, 40, _hoisted_2)
  ], 2))
}