
import {
  TemplateEditorContext,
  TemplateRegion
} from "@/admin/lib/templateEditorContext";
import { ComputedNode } from "@/admin/lib/templateMapper";
import { computed, defineComponent, inject, PropType, toRefs } from "vue";

export default defineComponent({
  props: {
    node: { type: Object as PropType<ComputedNode>, required: true },
    path: { type: Array as PropType<number[]>, required: true },
    region: { type: String as PropType<TemplateRegion>, required: true },
    pageNumber: { type: Number as PropType<number>, required: true }
  },
  setup(props) {
    const { node, path, region } = toRefs(props);

    const editorContext = inject(TemplateEditorContext);

    const isSelected = computed(
      () =>
        editorContext?.selectedRegion.value === region.value &&
        JSON.stringify(editorContext?.selectedPath.value) ===
          JSON.stringify(path.value)
    );

    const selectNode = () => {
      if (!editorContext) return;

      editorContext.selectNodePath(region.value, path.value);
    };

    const style = computed(() => ({
      top: `${node.value.top}px`,
      left: `${node.value.left}px`,
      width: `${node.value.width}px`,
      height: `${node.value.height}px`
    }));

    return { isSelected, selectNode, style };
  }
});
