
import { ComputedNode } from "@/admin/lib/templateMapper";
import Dropdown from "@/admin/components/Dropdown.vue";
import TextNode from "@/admin/components/TextNode.vue";
import PageNumberIndicator from "@/admin/components/PageNumberIndicator.vue";
import Input from "@/admin/components/Input.vue";
import AppendixReference from "@/admin/components/AppendixReference.vue";
import AppendixNumber from "@/admin/components/AppendixNumber.vue";
import { computed, defineComponent, inject, PropType, toRefs } from "vue";
import {
  TemplateRegion,
  TemplateEditorContext
} from "@/admin/lib/templateEditorContext";
import CustomText from "../CustomText.vue";

export default defineComponent({
  components: {
    Dropdown,
    CustomText,
    TextNode,
    AppendixReference,
    AppendixNumber,
    Input,
    PageNumberIndicator
  },
  props: {
    region: { type: String as PropType<TemplateRegion>, required: true },
    path: { type: Array as PropType<number[]>, required: true },
    node: { type: Object as PropType<ComputedNode>, required: true },
    parents: { type: Array as PropType<ComputedNode[]>, default: () => [] },
    pageNumber: { type: Number as PropType<number>, required: true }
  },
  setup(props) {
    const { region, path, node } = toRefs(props);

    const editorContext = inject(TemplateEditorContext);

    const style = computed(() => ({
      top: `${node.value.top}px`,
      left: `${node.value.left}px`,
      width: `${node.value.width}px`,
      height: `${node.value.height}px`
    }));

    const isSelected = computed(
      () => !!editorContext?.isSelected(region.value, path.value)
    );

    const canBeRemoved = computed(() =>
      editorContext?.canRemoveNode(path.value)
    );

    const selectNode = () => {
      if (!editorContext) return;

      editorContext.selectNodePath(region.value, path.value);
    };

    const onRemove = () => {
      if (!editorContext) return;

      editorContext.removeNode(region.value, path.value);
    };

    return {
      style,
      selectNode,
      isSelected,
      canBeRemoved,
      onRemove
    };
  }
});
