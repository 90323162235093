import { ref, Ref } from "vue";
import { ApiRequest, ApiError } from "@/api/client";

export type ApiResource<T, A extends unknown[]> = {
  isLoading: Ref<boolean>;
  error: Ref<ApiError | undefined>;
  data: Ref<T | undefined>;
  refresh: (...args: A) => Promise<void>;
};

export function useApiResource<A extends unknown[], T>(
  apiRequestBuilder: (...args: A) => ApiRequest<T>
): ApiResource<T, A> {
  const data: Ref<T | undefined> = ref(undefined);
  const error: Ref<ApiError | undefined> = ref(undefined);
  const isLoading = ref(false);

  const refresh = async (...args: A) => {
    const request = apiRequestBuilder(...args);
    isLoading.value = true;
    const response = await request();
    isLoading.value = false;
    if (response.error) {
      error.value = response.error;
    } else {
      error.value = undefined;
      data.value = response.result;
    }
  };

  return { isLoading, error, data, refresh };
}
