
import { ComputedNode } from "@/admin/lib/templateMapper";
import { defineComponent, PropType, computed } from "vue";

export default defineComponent({
  props: {
    node: { type: Object as PropType<ComputedNode>, required: true },
    pageNumber: { type: Number as PropType<number>, required: true },
    logo: { type: String, required: true },
    logoExtension: { type: String, required: true }
  },
  setup(props) {
    const extension = computed(() => {
      if (props.logoExtension === "svg") return "svg+xml";

      return props.logoExtension;
    });
    return {
      extension
    };
  }
});
