import { ApiResponse, apiClient } from "./client";
import { UserOrganization, OrganizationRole } from "./organizationApi";

export const getUsers =
  (query: string) => async (): Promise<ApiResponse<PagedUsers>> => {
    const response = await apiClient.get(`/organizations/users?${query}`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as PagedUsers };
    }
  };

export const createAccount =
  (data: CreateAccountData) => async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.post(`accounts`, { data });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export const login =
  (data: LoginData) => async (): Promise<ApiResponse<SessionData>> => {
    const response = await apiClient.post(`accounts/authenticate`, {
      data,
      nonLoggedErrorCodes: [400]
    });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as SessionData };
    }
  };

export const forgotPassword =
  (data: ForgotPasswordData) => async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.post(`accounts/forgot-password`, { data });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export const resetPassword =
  (data: ResetPasswordData) => async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.post(`accounts/reset-password`, { data });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export const changePassword =
  (data: ChangePasswordData) => async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.put(`accounts/change-password`, { data });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export const changeEmail =
  (data: ChangeEmailData) => async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.put(`accounts/change-email`, { data });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export const deleteAccount =
  (data: DeleteAccountData) => async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.post(`accounts/delete-account`, { data });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export type ChangeEmailData = {
  newEmail: string;
  currentPassword: string;
};

export type DeleteAccountData = {
  currentPassword: string;
};

export type ChangePasswordData = {
  newPassword: string;
  currentPassword: string;
};

export type ResetPasswordData = {
  email: string;
  password: string;
  token: string;
};

export type ForgotPasswordData = {
  email: string;
};

export type LoginData = {
  username: string;
  password: string;
};

export type CreateAccountData = {
  name: string;
  surname: string;
  email: string;
  password: string;
  captchaResponse: string | null;
};

export type SessionData = {
  id: string;
  firstName: string;
  lastName: string;
  surname: string;
  roles: string[];
  organizations: OrganizationSessionData[];
};

export type OrganizationSessionData = {
  organizationId: number;
  name: string;
  role: OrganizationRole;
  customerId: number | null;
  customerName?: string;
  isCustomerOwner: boolean;
  isCustomerAdmin: boolean;
};

export type ListUser = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  organizations: UserOrganization[];
};

export type PagedUsers = {
  records: ListUser[];
  totalRecords: number;
  page: number;
  pageSize: number;
};
