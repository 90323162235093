
import { ComputedNode } from "@/admin/lib/templateMapper";
import debounce from "lodash/debounce";
import { computed, defineComponent, inject, PropType, toRefs } from "vue";
import {
  CaseDetailsKey,
  CaseAppendicesKey,
  OverrideBaseTemplateSortOrderKey,
  SelectedTemplateKey
} from "./types";

export default defineComponent({
  props: {
    node: { type: Object as PropType<ComputedNode>, required: true },
    caseReadOnly: { type: Boolean }
  },
  setup(props) {
    const { node } = toRefs(props);

    const caseDetails = inject(CaseDetailsKey);
    const caseAppendices = inject(CaseAppendicesKey);
    const selectedTemplate = inject(SelectedTemplateKey);
    const overrideSortOrder = inject(
      OverrideBaseTemplateSortOrderKey,
      () => void 0
    );

    const appendixNumber = computed(
      () =>
        (selectedTemplate?.value && caseAppendices?.value
          ? caseAppendices.value.find(t => t.id === selectedTemplate?.value?.id)?.customAppendixNumber || caseAppendices.value.find(t => t.id === selectedTemplate?.value?.id)?.sortOrder
          : undefined) ?? 0
    );

    const onInputChanged = debounce((event: Event) => {
      if (appendixNumber.value !== 0) return;
      const inputElement = event.target as HTMLInputElement;
      overrideSortOrder(inputElement.value);
    }, 250);

    const baseTemplateSortOrderOverride = computed(() =>
      appendixNumber.value === 0
        ? caseDetails?.value?.baseTemplateSortOrderOverride
        : false
    );

    const hasLabel = computed(
      () =>
        node.value.values &&
        "hasLabel" in node.value.values &&
        node.value.values.hasLabel
    );

    const label = computed(() =>
      node.value.values &&
      "label" in node.value.values &&
      node.value.values.label
        ? (node.value.values.label as string)
        : ""
    );

    return {
      appendixNumber,
      baseTemplateSortOrderOverride,
      hasLabel,
      label,
      onInputChanged
    };
  }
});
