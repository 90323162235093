
import { ComputedNode } from "@/admin/lib/templateMapper";
import { NodeDefinition } from "@/api/types";
import { computed, defineComponent, PropType, toRefs } from "vue";
import ContractNodeTextField from "./ContractNodeTextField.vue";
import ContractNodeCheckbox from "./ContractNodeCheckbox.vue";
import ContractNodeDropdown from "./ContractNodeDropdown.vue";
import ContractNodeText from "./ContractNodeText.vue";
import ContractNodeCustomText from "./ContractNodeCustomText.vue";
import ContractNodeAppendixReference from "./ContractNodeAppendixReference.vue";
import ContractNodeAppendixNumber from "./ContractNodeAppendixNumber.vue";
import ContractNodeSection from "./ContractNodeSection.vue";
import ContractNodeFooter from "./ContractNodeFooter.vue";
import ContractNodeHeader from "./ContractNodeHeader.vue";

export default defineComponent({
  components: {
    ContractNodeTextField,
    ContractNodeCheckbox,
    ContractNodeDropdown,
    ContractNodeText,
    ContractNodeCustomText,
    ContractNodeAppendixReference,
    ContractNodeAppendixNumber,
    ContractNodeSection,
    ContractNodeHeader,
    ContractNodeFooter
  },
  props: {
    node: { type: Object as PropType<ComputedNode>, required: true },
    labelFor: {
      type: String,
      required: false
    },
    groupId: {
      type: String,
      required: false
    },
    exclusive: {
      type: Boolean,
      default: false
    },
    index: { type: Number as PropType<number>, required: false },
    pageNumber: { type: Number as PropType<number>, required: true },
    logo: {
      type: String,
      required: false
    },
    logoExtension: {
      type: String,
      required: false
    },
    caseReadOnly: {
      type: Boolean
    },
    showValidationFailures: {
      type: Boolean,
      default: false
    },
    insideCustomTextSection: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { node, insideCustomTextSection } = toRefs(props);

    const isCustomTextSection = (node: NodeDefinition) => {
      const customTextNode = node.children[0];
      return (
        customTextNode &&
        "control" in customTextNode &&
        customTextNode.control === "CustomText"
      );
    };

    const style = computed(() => {
      const height =
        insideCustomTextSection.value ||
        (node.value.kind === "Section" &&
          node.value.children &&
          node.value.children[0].control === "CustomText")
          ? "auto"
          : `${node.value.height}px`;

      return {
        top: `${node.value.top}px`,
        left: `${node.value.left}px`,
        width: `${node.value.width}px`,
        height
      };
    });

    return {
      isCustomTextSection,
      style,
      nodeWidth: node.value.width
    };
  }
});
