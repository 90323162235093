
import { v4 as uuidv4 } from "uuid";
import { ComputedNode } from "@/admin/lib/templateMapper";
import {
  computed,
  defineComponent,
  inject,
  PropType,
  ref,
  toRefs,
  watch,
  watchEffect
} from "vue";
import { controlledComputed } from "@vueuse/core";
import { useApiResource } from "@/use/apiResource";
import { getCaseTemplate } from "@/api/templateApi";
import {
  AddValueFieldKey,
  CaseTemplate,
  CaseDetailsKey,
  CaseAppendicesKey,
  RelatedTemplatesKey,
  ValueFieldsKey
} from "./types";
import { useI18n } from "vue-i18n";
import {
  DocumentTextIcon,
  StarIcon,
  InformationCircleIcon
} from "@heroicons/vue/outline";
import { ChevronDownIcon } from "@heroicons/vue/solid";
import ContractCustomAppendixFileAndPlaceholder from "./ContractCustomAppendixFileAndPlaceholder.vue";

export default defineComponent({
  components: {
    DocumentTextIcon,
    ChevronDownIcon,
    StarIcon,
    InformationCircleIcon,
    ContractCustomAppendixFileAndPlaceholder
  },
  props: {
    node: {
      type: Object as PropType<ComputedNode>,
      required: true
    },
    caseReadOnly: {
      type: Boolean
    }
  },
  setup(props) {
    const { t } = useI18n();
    const { node } = toRefs(props);
    const nodeId = props.node.values?.id?.toString() ?? "";
    const isOpen = ref(false);
    const valueFields = inject(ValueFieldsKey);
    const valueField = controlledComputed(
      () => !!valueFields?.value[nodeId],
      () => valueFields?.value[nodeId]
    );
    const {
      data: caseTemplateData,
      refresh: fetchCaseTemplate,
      isLoading: isLoading,
      error: error
    } = useApiResource(getCaseTemplate);
    const addValueField = inject(AddValueFieldKey, () => void 0);
    const relatedTemplates = inject(RelatedTemplatesKey);
    const caseTemplates = inject(CaseAppendicesKey);
    const recommendedAppendices = computed(
      () => (node.value.values?.appendixIds as Array<number>) ?? []
    );

    const selectedOptions = ref<Array<string>>([]);
    const maxSelectedOptions = ref(false);
    watchEffect(() => {
      selectedOptions.value =
        !valueField.value ||
        valueField.value.values.valueKind !== "AppendixReference"
          ? []
          : valueField.value.values.referenceIds ?? [];
    });

    const appendicesInCase = computed(
      () =>
        caseTemplates?.value
          .filter(
            a =>
              relatedTemplates?.value.some(
                rt => rt.templateId === a.templateId
              ) ||
              a.kind === "Placeholder" ||
              a.kind === "Upload"
          )
          .map(a => ({
            id: a.id,
            name: getAppendixName(a),
            recommended: recommendedAppendices.value.includes(
              a.templateId ?? -1
            ),
            selected: selectedOptions.value?.includes(a.id),
            sortOrder: a.sortOrder,
            customAppendixNumber: a.customAppendixNumber
          }))
          .sort((a, b) => Number(a.sortOrder) - Number(b.sortOrder)) ?? []
    );

    const availableTemplates = computed(
      () =>
        relatedTemplates?.value
          .filter(
            t =>
              t.activeVersionId !== null &&
              (t.allowBeingAddedMultipleTimes ||
                !caseTemplates?.value.some(ct => ct.templateId === t.id))
          )
          .map(a => ({
            id: a.templateId ?? a.id,
            name: a.name,
            recommended: recommendedAppendices.value.includes(a.id),
            selected: false,
            sortOrder: 0,
            referenceNumber: a.referenceNumber
          }))
          .sort((a, b) => Number(b.recommended) - Number(a.recommended)) ?? []
    );

    const caseDetails = inject(CaseDetailsKey);

    const getAppendixName = (appendix: CaseTemplate): string => {
      if (appendix?.name && appendix.name !== "") {
        return appendix.name;
      }
      if (appendix.kind === "Placeholder") {
        return t("editContractPage.placeholder");
      }
      
      return `${appendix.referenceNumber || ''} ${appendix.templateName || ''}`;
    };

    const getUpdatedReferenceIdList = (
      appendixId: string | undefined
    ): string[] => {
      let ids: string[] = [];
      if (
        valueField.value &&
        valueField.value.values.valueKind === "AppendixReference"
      ) {
        ids = valueField.value.values.referenceIds ?? [];
      }
      if (!appendixId) {
        ids = [];
      } else if (ids.includes(appendixId)) {
        ids = ids.filter(id => id !== appendixId);
      } else if (ids.length < 3) {
        ids = [...ids, appendixId];
      }
      return ids;
    };

    const setValueField = (ids: string[]) => {
      if (!valueField.value) {
        addValueField({
          name: nodeId,
          readOnly: false,
          values: {
            valueKind: "AppendixReference",
            referenceIds: ids,
            customFile: false //Todo: This needs to be set based on what is choosen
          },
          valueKind: "AppendixReference"
        });
        return;
      }
      if (valueField.value.values.valueKind !== "AppendixReference") return;
      valueField.value.values = {
        ...valueField.value.values,
        referenceIds: ids,
        customFile: false //Todo: This needs to be set based on what is choosen
      };
    };

    const handleClickOnAvailableTemplate = async (templateId: number) => {
      if (!caseTemplates) {
        return;
      }
      if (
        valueField.value &&
        valueField.value.values.valueKind === "AppendixReference" &&
        valueField.value.values.referenceIds.length === 3
      ) {
        return;
      }
      isOpen.value = false;
      await fetchCaseTemplate(templateId);

      if (!caseDetails?.value || !caseTemplateData?.value) return;
      const sortOrder =
        caseTemplates.value.sort((a, b) => a.sortOrder - b.sortOrder)[
          caseTemplates.value.length - 1
        ]?.sortOrder + 1 ?? 0;
      const id = uuidv4();
      const ids = getUpdatedReferenceIdList(id);

      caseDetails.value.appendices = [
        ...caseDetails.value.appendices,
        {
          ...caseTemplateData.value,
          name: "", //Ensure we use template name
          templateName: caseTemplateData.value.name,
          kind: "Template",
          id,
          sortOrder: sortOrder,
          valueFields: []
        }
      ];
      selectedOptions.value = ids;
      setValueField(ids);
    };

    const handleClickOnNoAppendix = () => {
      if (!caseTemplates) {
        return;
      }
      selectedOptions.value = [];
      isOpen.value = false;

      setValueField([]);
    };

    function onAppendixAdded(id: string) {
      handleClickOnCaseAppendix(id);
    }

    const handleClickOnCaseAppendix = (appendixId: string) => {
      if (!caseTemplates) {
        return;
      }
      const ids = getUpdatedReferenceIdList(appendixId);
      selectedOptions.value = ids;
      isOpen.value = false;

      setValueField(ids);
    };

    watch(
      () => selectedOptions.value,
      selected => {
        maxSelectedOptions.value = selected.length === 3;
      },
      { immediate: true }
    );

    watch(
      () => caseDetails?.value?.appendices,
      newValue => {
        const values =
          selectedOptions.value?.filter(so =>
            newValue?.find(t => t.id === so)
          ) ?? [];
        const field = valueField.value;
        const fieldValues = field?.values;
        const referenceIds =
          fieldValues &&
          "referenceIds" in fieldValues &&
          fieldValues.referenceIds;
        if (
          values !== referenceIds &&
          valueField.value &&
          valueField.value.values.valueKind === "AppendixReference"
        ) {
          valueField.value.values = {
            ...valueField.value.values,
            referenceIds: values
          };
        }
      }
    );
    const selectedAppendicesSortOrder = computed(() =>
      caseDetails?.value?.appendices
        .filter(t => selectedOptions.value?.includes(t.id))
        .map(t => t.customAppendixNumber || t.sortOrder)
        .join(",")
    );
    watch(
      () => error.value,
      () => {
        alert(t("contractNodeAppendixReference.error"));
      }
    );
    return {
      t,
      valueField,
      selectedOptions,
      selectedAppendicesSortOrder,
      getAppendixName,
      handleClickOnNoAppendix,
      handleClickOnCaseAppendix,
      handleClickOnAvailableTemplate,
      appendicesInCase,
      availableTemplates,
      isOpen,
      isLoading,
      maxSelectedOptions,
      onAppendixAdded
    };
  }
});
