import axios, { AxiosError } from "axios";
import {
  Method,
  RequestOptionsWithData,
  OkResponse,
  ErrorResponse,
  RequestOptions,
  ApiError
} from "./types";
import rg4js from "raygun4js";
import i18n from "@/i18n";
import router, { routeNames } from "@/router";
import { useSession } from "@/use/session";

function isAxiosError(error: unknown): error is AxiosError {
  return (error as AxiosError)?.isAxiosError;
}

export const baseURL = "/api";

const axiosClient = axios.create({
  baseURL: baseURL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

export function configureClient(): void {
  axiosClient.interceptors.response.use(
    response => response,
    async error => {
      if (isAxiosError(error) && error?.response?.status === 401) {
        // eslint-disable-next-line no-console
        clearSession();
        if (
          router.currentRoute.value.name !== routeNames.templateList &&
          !router.currentRoute.value.matched.some(
            n => n.name === routeNames.external
          )
        ) {
          await router.push({
            name: routeNames.templateList
          });
        }
      }

      return Promise.reject(error);
    }
  );
}

type ResponseDataError = {
  message: string | undefined;
  data: unknown | undefined;
};

function isDataWithErrorMessage(data: unknown): data is ResponseDataError {
  const maybe = data as ResponseDataError;

  return !!maybe.message;
}

const { clearSession } = useSession();

async function request(
  method: Method,
  url: string,
  options?: RequestOptionsWithData
): Promise<OkResponse | ErrorResponse> {
  try {
    const response = await axiosClient.request({
      url,
      method,
      ...options
    });

    return {
      hasError: false,
      status: response.status,
      data: response.data,
      headers: response.headers as { [key: string]: string }
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status) {
      const status = error.response ? error.response.status : 0;

      if (status === 401) {
        return {
          hasError: true,
          status,
          error: { message: "Unauthorized" }
        };
      }

      const apiError: ApiError = isDataWithErrorMessage(error.response.data)
        ? {
            message:
              error.response.data.message ??
              `Unexpected error (Status ${status})`,
            data: error.response.data.data ?? undefined
          }
        : {
            message:
              (error.response.data as ResponseDataError)?.message ??
              `Unexpected error (Status ${status})`
          };

      if (!options?.nonLoggedErrorCodes?.includes(status)) {
        rg4js("send", {
          error: error instanceof Error ? error : new Error(apiError.message)
        });
      }

      return {
        hasError: true,
        status,
        error: apiError
      };
    }

    // eslint-disable-next-line no-console
    console.error("Api request failed with error: ", error);
    rg4js("send", {
      error: error instanceof Error ? error : new Error("Api request failed")
    });

    return {
      hasError: true,
      status: 0,
      error: { message: i18n.global.t("common.error").toString() }
    };
  }
}

export const apiClient: ApiClient = {
  get: (url, options) => request("GET", url, options),
  put: (url, options) => request("PUT", url, options),
  post: (url, options) => request("POST", url, options),
  patch: (url, options) => request("PATCH", url, options),
  delete: (url, options) => request("DELETE", url, options)
};

export type ApiClient = {
  get: (
    url: string,
    options?: RequestOptions
  ) => Promise<OkResponse | ErrorResponse>;
  put: (
    url: string,
    options?: RequestOptionsWithData
  ) => Promise<OkResponse | ErrorResponse>;
  post: (
    url: string,
    options?: RequestOptionsWithData
  ) => Promise<OkResponse | ErrorResponse>;
  patch: (
    url: string,
    options?: RequestOptionsWithData
  ) => Promise<OkResponse | ErrorResponse>;
  delete: (
    url: string,
    options?: RequestOptions
  ) => Promise<OkResponse | ErrorResponse>;
};
