
import { useI18n } from "vue-i18n";
import { ICursorPosition, ITreeNode, ITree } from "./types";
import {
  defineComponent,
  PropType,
  onMounted,
  onBeforeUnmount,
  toRefs,
  computed,
  ref,
  InjectionKey,
  provide,
  inject,
  getCurrentInstance,
  ComponentInternalInstance
} from "vue";

const Root: InjectionKey<ITree<unknown>> = Symbol();
const RootEmit: InjectionKey<ComponentInternalInstance["emit"]> = Symbol();

export default defineComponent({
  props: {
    modelValue: {
      type: Array as PropType<ITreeNode<unknown>[]>,
      default: () => []
    },
    edgeSize: {
      type: Number as PropType<number>,
      default: 3
    },
    showBranches: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    level: {
      type: Number as PropType<number>,
      default: 0
    },
    parentInd: {
      type: Number as PropType<number>,
      default: 0
    },
    allowToggleBranch: {
      type: Boolean as PropType<boolean>,
      default: true
    },
    scrollAreaHeight: {
      type: Number as PropType<number>,
      default: 70
    },
    maxScrollSpeed: {
      type: Number as PropType<number>,
      default: 20
    }
  },
  emits: ["select", "beforedrop", "drop", "toggle"],
  setup(props) {
    const {
      modelValue,
      edgeSize,
      showBranches,
      level,
      allowToggleBranch,
      scrollAreaHeight,
      maxScrollSpeed
    } = toRefs(props);
    const { t } = useI18n();

    const rootCursorPosition = ref<ICursorPosition<unknown> | null>(null);
    const scrollIntervalId = ref(0);
    const scrollSpeed = ref(0);
    const rootLastSelectedNode = ref<ITreeNode<unknown> | null>(null);
    const draggingNode = ref<ITreeNode<unknown> | null>(null);
    const mouseIsDown = ref(false);
    const isDragging = ref(false);
    const lastMousePos = ref({ x: 0, y: 0 });
    const preventDrag = ref(false);
    const dragInfo = ref<HTMLDivElement>();
    const treeRoot = ref<HTMLDivElement>();
    const cursor = ref<HTMLDivElement>();

    onMounted(() => {
      if (isRoot.value) {
        document.addEventListener("mouseup", onDocumentMouseupHandler);
      }
    });

    onBeforeUnmount(() => {
      if (isRoot.value) {
        document.removeEventListener("mouseup", onDocumentMouseupHandler);
      }
    });

    const cursorPosition = computed(() => {
      if (isRoot.value) return rootCursorPosition.value;
      return $root.cursorPosition;
    });

    const lastSelectedNode = computed(() => {
      if (isRoot.value) return rootLastSelectedNode.value;
      return $root.lastSelectedNode;
    });

    const nodes = computed(() => {
      if (isRoot.value) {
        patchNodes(modelValue.value);
      }

      return modelValue.value;
    });

    /**
     * gaps is using for nodes indentation
     * @returns {number[]}
     */
    const gaps = computed(() => {
      const gaps: number[] = [];
      let i = level.value - 1;
      if (!showBranches.value) i++;
      while (i-- > 0) gaps.push(i);
      return gaps;
    });

    const isRoot = computed(() => {
      return !level.value;
    });

    function setCursorPosition(pos: ICursorPosition<unknown> | null): void {
      if (isRoot.value) {
        rootCursorPosition.value = pos;
        return;
      }
      $root.setCursorPosition(pos);
    }

    function setLastSelectedNode(node: ITreeNode<unknown> | null): void {
      if (isRoot.value) {
        rootLastSelectedNode.value = node;
        return;
      }
      $root.setLastSelectedNode(node);
    }

    function setCursorTopPosition(value: number): void {
      if (isRoot.value) {
        if (cursor.value) {
          cursor.value.style.top = `${value}px`;
        }
        return;
      }
      $root.setCursorTopPosition(value);
    }

    function setCursorHeight(value: number): void {
      if (isRoot.value) {
        if (cursor.value) {
          cursor.value.style.height = `${value}px`;
        }
        return;
      }
      $root.setCursorHeight(value);
    }

    function patchNodes(
      nodes: ITreeNode<unknown>[],
      parentPath: number[] = [],
      isVisible = true
    ): void {
      nodes.forEach((node, ind) =>
        patchNode(parentPath.concat(ind), node, nodes, isVisible)
      );
    }

    function getNode(
      path: number[],
      siblings: ITreeNode<unknown>[] | null = null
    ): ITreeNode<unknown> | undefined {
      const ind = path.slice(-1)[0];

      return siblings ? siblings[ind] : getNodeSiblings(nodes.value, path)[ind];
    }

    function patchNode(
      path: number[],
      node: ITreeNode<unknown> | null = null,
      siblings: ITreeNode<unknown>[] | null = null,
      visible: boolean | null = null
    ): void {
      const ind = path.slice(-1)[0];

      // calculate nodeModel, siblings, isVisible fields if it is not passed as arguments
      siblings = siblings || getNodeSiblings(nodes.value, path);
      node = node || (siblings && siblings[ind]) || null;

      if (visible == null) {
        visible = isVisible(path);
      }

      if (!node) return;

      patchNodes(node.children, path, node.isExpanded);

      node.isSelected = !!node.isSelected;
      node.isVisible = visible;
      node.path = path;
      const pathStr = JSON.stringify(path);
      if (pathStr !== node.pathStr) {
        node.pathStr = pathStr;
      }
      node.level = path.length;
      node.ind = ind;
      node.isFirstChild = ind === 0;
      node.isLastChild = ind === siblings.length - 1;
    }

    function isVisible(path: number[]): boolean {
      if (path.length < 2) return true;
      let nodeList = nodes.value;

      for (let i = 0; i < path.length - 1; i++) {
        const ind = path[i];
        const node = nodeList[ind];
        const isExpanded = node.isExpanded;
        if (!isExpanded) return false;
        nodeList = node.children ?? [];
      }

      return true;
    }

    function emitSelect(
      selectedNode: ITreeNode<unknown>,
      event: MouseEvent | null
    ): void {
      $rootEmit("select", selectedNode, event);
    }

    function emitBeforeDrop(
      position: ICursorPosition<unknown>,
      cancel: () => boolean
    ): void {
      $rootEmit("beforedrop", draggingNode.value, position, cancel);
    }

    function emitDrop(
      position: ICursorPosition<unknown>,
      event: MouseEvent
    ): void {
      $rootEmit("drop", draggingNode.value, position, event);
    }

    function emitToggle(
      toggledNode: ITreeNode<unknown>,
      event: MouseEvent
    ): void {
      $rootEmit("toggle", toggledNode, event);
    }

    function onExternalDropHandler(
      node: ITreeNode<unknown>,
      event: DragEvent
    ): void {
      const cursorPosition = $root.getCursorPositionFromCoords(
        event.clientX,
        event.clientY
      );
      if (cursorPosition) {
        setCursorPosition(null);
      }
    }

    function select(path: number[]): void {
      const selectedNode = getNode(path);
      if (!selectedNode) return;

      selectNode(selectedNode);
    }

    function selectNode(
      node: ITreeNode<unknown>,
      event: MouseEvent | null = null
    ): void {
      if (lastSelectedNode.value) {
        lastSelectedNode.value.isSelected = false;
      }

      node.isSelected = true;
      setLastSelectedNode(node);

      emitSelect(node, event);
    }

    function onMousemoveHandler(event: MouseEvent): void {
      if (!isRoot.value) {
        $root.onMousemoveHandler(event);
        return;
      }

      if (preventDrag.value) return;

      const initialDraggingState = isDragging.value;
      const dragging =
        isDragging.value ||
        (mouseIsDown.value &&
          (lastMousePos.value.x !== event.clientX ||
            lastMousePos.value.y !== event.clientY));

      const isDragStarted = initialDraggingState === false && dragging === true;

      if (
        lastMousePos.value.x !== event.clientX ||
        lastMousePos.value.y !== event.clientY
      ) {
        lastMousePos.value = {
          x: event.clientX,
          y: event.clientY
        };
      }

      if (!dragging || !treeRoot.value) return;

      const cursorPosition = getCursorPositionFromCoords(
        event.clientX,
        event.clientY
      );
      if (!cursorPosition) return;

      const destNode = cursorPosition.node;
      if (isDragStarted) {
        if (destNode.isDraggable) {
          draggingNode.value = destNode;
        } else {
          draggingNode.value = null;
        }

        if (!destNode.isSelected && destNode.path) {
          selectNode(destNode, event);
        }
      }

      if (!draggingNode.value) {
        preventDrag.value = true;
        return;
      }

      const rootRect = treeRoot.value.getBoundingClientRect();

      if (dragInfo.value) {
        const dragInfoTop =
          event.clientY -
          rootRect.top +
          treeRoot.value.scrollTop -
          parseInt(dragInfo.value?.style.marginBottom || "0", 10);
        const dragInfoLeft = event.clientX - rootRect.left;

        dragInfo.value.style.top = `${dragInfoTop}px`;
        dragInfo.value.style.left = `${dragInfoLeft}px`;
      }

      const placement = cursorPosition.placement;
      if (cursorPosition.rect) {
        switch (placement) {
          case "before":
            setCursorTopPosition(cursorPosition.rect.top - rootRect.top);
            setCursorHeight(1);
            break;
          case "inside":
            setCursorTopPosition(cursorPosition.rect.top - rootRect.top);
            setCursorHeight(cursorPosition.rect.height);
            break;
          case "after":
            setCursorTopPosition(cursorPosition.rect.bottom - rootRect.top);
            setCursorHeight(1);
            break;
        }
      }

      isDragging.value = dragging;
      setCursorPosition({ node: destNode, placement });

      const scrollBottomLine = rootRect.bottom - scrollAreaHeight.value;
      const scrollDownSpeed =
        (event.clientY - scrollBottomLine) /
        (rootRect.bottom - scrollBottomLine);
      const scrollTopLine = rootRect.top + scrollAreaHeight.value;
      const scrollTopSpeed =
        (scrollTopLine - event.clientY) / (scrollTopLine - rootRect.top);

      if (scrollDownSpeed > 0) {
        startScroll(scrollDownSpeed);
      } else if (scrollTopSpeed > 0) {
        startScroll(-scrollTopSpeed);
      } else {
        stopScroll();
      }
    }

    function getCursorPositionFromCoords(
      x: number,
      y: number
    ): ICursorPosition<unknown> | null {
      const $target = document.elementFromPoint(x, y);
      const $nodeItem = getClosetElementWithPath($target);
      let destNode: ITreeNode<unknown> | undefined;
      let placement: "before" | "inside" | "after";
      let rect: DOMRect | null = null;

      if ($nodeItem) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        destNode = getNode(JSON.parse($nodeItem.path));

        const nodeHeight = $nodeItem.element.offsetHeight;
        const es = edgeSize.value;
        rect = $nodeItem.element.getBoundingClientRect();
        const offsetY = y - rect.top;

        if (destNode?.isLeaf) {
          placement = offsetY >= nodeHeight / 2 ? "after" : "before";
        } else {
          if (offsetY <= es) {
            placement = "before";
          } else if (offsetY >= nodeHeight - es) {
            placement = "after";
          } else {
            placement = "inside";
          }
        }
      } else if (treeRoot.value) {
        rect = treeRoot.value.getBoundingClientRect();
        const rootRect = rect;
        if (y > rootRect.top + rootRect.height / 2) {
          placement = "after";
          destNode = getLastNode();
        } else {
          placement = "before";
          destNode = getFirstNode();
        }
      } else {
        return null;
      }

      if (!destNode) return null;

      return { node: destNode, placement, rect };
    }

    function getClosetElementWithPath(
      $el: Element | null
    ): { element: HTMLElement; path: string } | null {
      if (!$el) return null;
      const path = $el.getAttribute("path");
      if (path) return { element: $el as HTMLElement, path };
      return getClosetElementWithPath($el.parentElement);
    }

    function onMouseleaveHandler(event: MouseEvent): void {
      if (!isRoot.value || !isDragging.value || !treeRoot.value) return;
      const rootRect = treeRoot.value.getBoundingClientRect();
      if (event.clientY >= rootRect.bottom) {
        setCursorPosition({
          node: nodes.value.slice(-1)[0],
          placement: "after"
        });
      } else if (event.clientY < rootRect.top) {
        const node = getFirstNode();
        if (node) {
          setCursorPosition({
            node,
            placement: "before"
          });
        }
      }
    }

    function getLastNode(): ITreeNode<unknown> | undefined {
      let lastNode: ITreeNode<unknown> | undefined;
      traverse(node => {
        lastNode = node;

        return true;
      });
      return lastNode;
    }

    function getFirstNode(): ITreeNode<unknown> | undefined {
      return getNode([0]);
    }

    function onNodeMousedownHandler(
      event: MouseEvent,
      node: ITreeNode<unknown>
    ): void {
      // handle only left mouse button
      if (event.button !== 0) return;

      if (!isRoot.value) {
        $root.onNodeMousedownHandler(event, node);
        return;
      }
      mouseIsDown.value = true;
    }

    function startScroll(speed: number): void {
      if (scrollSpeed.value === speed || !treeRoot.value) {
        return;
      } else if (scrollIntervalId.value) {
        stopScroll();
      }

      const $treeRoot = treeRoot.value;
      scrollSpeed.value = speed;
      scrollIntervalId.value = setInterval(() => {
        $treeRoot.scrollTop += maxScrollSpeed.value * speed;
      }, 20);
    }

    function stopScroll(): void {
      clearInterval(scrollIntervalId.value);
      scrollIntervalId.value = 0;
      scrollSpeed.value = 0;
    }

    function onDocumentMouseupHandler(event: MouseEvent): void {
      if (isDragging.value) onNodeMouseupHandler(event);
    }

    function onNodeMouseupHandler(
      event: MouseEvent,
      targetNode: ITreeNode<unknown> | null = null
    ): void {
      // handle only left mouse button
      if (event.button !== 0) return;

      if (!isRoot.value) {
        $root.onNodeMouseupHandler(event, targetNode);
        return;
      }

      mouseIsDown.value = false;

      if (
        !isDragging.value &&
        targetNode &&
        targetNode.path &&
        !preventDrag.value
      ) {
        selectNode(targetNode, event);
      }

      preventDrag.value = false;

      if (!cursorPosition.value || !draggingNode.value) {
        stopDrag();
        return;
      }

      if (draggingNode.value.pathStr == cursorPosition.value.node.pathStr) {
        stopDrag();
        return;
      }

      if (checkNodeIsParent(draggingNode.value, cursorPosition.value.node)) {
        stopDrag();
        return;
      }

      // allow the drop to be cancelled
      let cancelled = false;
      emitBeforeDrop(cursorPosition.value, () => (cancelled = true));

      if (cancelled) {
        stopDrag();
        return;
      }

      // delete dragging node from the old place
      traverseModels((node, siblings, ind) => {
        if (draggingNode?.value === node) {
          siblings.splice(ind, 1);
        }
      }, nodes.value);

      // insert dragging node to the new place
      insertModel(cursorPosition.value, draggingNode.value, false);

      emitDrop(cursorPosition.value, event);
      stopDrag();
    }

    function onToggleHandler(
      event: MouseEvent,
      node: ITreeNode<unknown>
    ): void {
      if (!allowToggleBranch.value) return;

      updateNode(node.path ?? [], { isExpanded: !node.isExpanded });
      emitToggle(node, event);
      event.stopPropagation();
    }

    function stopDrag(): void {
      draggingNode.value = null;
      isDragging.value = false;
      mouseIsDown.value = false;
      setCursorPosition(null);
      setCursorTopPosition(-9999);
      setCursorHeight(1);
      stopScroll();
    }

    function getNodeSiblings(
      nodes: ITreeNode<unknown>[],
      path: number[]
    ): ITreeNode<unknown>[] {
      if (!nodes.length) return [];
      if (path.length === 1) return nodes;
      return getNodeSiblings(nodes[path[0]]?.children ?? [], path.slice(1));
    }

    function updateNode(
      path: number[],
      patch: Partial<ITreeNode<unknown>>
    ): void {
      if (!isRoot.value) {
        $root.updateNode(path, patch);
        return;
      }

      const pathStr = JSON.stringify(path);
      traverse(node => {
        if (node.pathStr !== pathStr) return true;
        Object.assign(node, patch);

        return false;
      });
    }

    function traverse(
      cb: (n: ITreeNode<unknown>, nms: ITreeNode<unknown>[] | null) => boolean,
      nodeList: ITreeNode<unknown>[] | null = null
    ): boolean {
      if (!nodeList) nodeList = nodes.value;

      let shouldStop = false;

      const visited: ITreeNode<unknown>[] = [];

      for (let nodeInd = 0; nodeInd < nodeList.length; nodeInd++) {
        const node = nodeList[nodeInd];
        shouldStop = !cb(node, nodeList);
        visited.push(node);

        if (shouldStop) break;

        if (node.children.length) {
          shouldStop = !traverse(cb, node.children);
          if (shouldStop) break;
        }
      }

      return !shouldStop ? !!visited.length : false;
    }

    function traverseModels(
      cb: (nm: ITreeNode<unknown>, s: ITreeNode<unknown>[], i: number) => void,
      nodes: ITreeNode<unknown>[]
    ): void {
      let i = nodes.length;
      while (i--) {
        const node = nodes[i];
        if (node.children) {
          traverseModels(cb, node.children);
        }
        cb(node, nodes, i);
      }
    }

    function remove(paths: number[][]): void {
      const pathsStr = paths.map(path => JSON.stringify(path));
      const markToDelete: ITreeNode<unknown>[] = [];
      traverse(node => {
        for (const pathStr of pathsStr) {
          if (node.pathStr === pathStr) {
            markToDelete.push(node);
          }
        }

        return true;
      });

      traverseModels((node, siblings, ind) => {
        if (!markToDelete.some(x => x === node)) return;
        siblings.splice(ind, 1);
      }, nodes.value);
    }

    function insertModel(
      cursorPosition: ICursorPosition<unknown>,
      nodeToInsert: ITreeNode<unknown>,
      updatePaths: boolean
    ): void {
      const destNode = cursorPosition.node;

      if (cursorPosition.placement === "inside") {
        if (updatePaths) {
          const path = destNode.path ?? [];
          nodeToInsert.path = [...path, 0];
        }
        destNode.children = destNode.children || [];
        destNode.children.unshift(nodeToInsert);
        destNode.isExpanded = true;
      } else {
        let path = destNode.path ?? [];
        const destSiblings = getNodeSiblings(nodes.value, path);
        const insertInd =
          cursorPosition.placement === "before"
            ? destNode.ind ?? 0
            : (destNode.ind ?? 0) + 1;
        if (updatePaths) {
          path = path.slice(0, -1);
          nodeToInsert.path = [...path, insertInd];
        }

        destSiblings.splice(insertInd, 0, nodeToInsert);
      }
    }

    function insert(
      cursorPosition: ICursorPosition<unknown>,
      node: ITreeNode<unknown>
    ): void {
      insertModel(cursorPosition, node, true);
      node.isSelected = true;
      if (lastSelectedNode.value) {
        lastSelectedNode.value.isSelected = false;
      }
      setLastSelectedNode(node);
      emitSelect(node, null);
    }

    function checkNodeIsParent(
      sourceNode: ITreeNode<unknown>,
      destNode: ITreeNode<unknown>
    ): boolean {
      const destPath = destNode.path ?? [];
      return (
        JSON.stringify(destPath.slice(0, sourceNode.path?.length ?? 0)) ===
        sourceNode.pathStr
      );
    }

    let $root: ITree<unknown>;
    let $rootEmit: ComponentInternalInstance["emit"];
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const instance = getCurrentInstance()!;
    if (isRoot.value) {
      $root = instance.proxy as unknown as ITree<unknown>;
      $rootEmit = instance.emit;

      provide(Root, $root);
      provide(RootEmit, $rootEmit);
    } else {
      $root = inject(Root, instance.proxy as unknown as ITree<unknown>);
      $rootEmit = inject(RootEmit, instance.emit);
    }

    return {
      t,
      nodes,
      isRoot,
      cursorPosition,
      cursor,
      treeRoot,
      gaps,
      isDragging,
      draggingNode,
      dragInfo,
      getNode,
      select,
      updateNode,
      traverse,
      remove,
      insert,
      lastSelectedNode,
      setLastSelectedNode,
      setCursorPosition,
      setCursorTopPosition,
      setCursorHeight,
      getCursorPositionFromCoords,
      onMousemoveHandler,
      onMouseleaveHandler,
      onNodeMousedownHandler,
      onNodeMouseupHandler,
      onExternalDropHandler,
      onToggleHandler
    };
  }
});
