import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row p-0" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["disabled"]
const _hoisted_4 = {
  key: 0,
  class: "pl-1 text-gray-500 truncate"
}
const _hoisted_5 = {
  key: 1,
  class: "pl-1"
}
const _hoisted_6 = {
  key: 1,
  class: "origin-top-right absolute right-0 mt-2 pb-2 max-h-80 overflow-y-scroll w-[21.5rem] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50",
  role: "menu",
  "aria-orientation": "vertical",
  "aria-labelledby": "menu-button",
  tabindex: "-1"
}
const _hoisted_7 = {
  key: 0,
  class: "px-2 pt-2 flex items-center justify-center"
}
const _hoisted_8 = { class: "ml-2 text-xs text-gray-500" }
const _hoisted_9 = {
  key: 1,
  role: "none"
}
const _hoisted_10 = { class: "text-fa-orange" }
const _hoisted_11 = {
  key: 2,
  class: "py-1",
  role: "none"
}
const _hoisted_12 = {
  class: "text-current group flex items-center px-2 pt-2 pb-1 text-sm font-semibold",
  tabindex: "-1"
}
const _hoisted_13 = { role: "none" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { class: "rounded-full mr-2 h-5 min-w-[1.25rem] flex items-center justify-center bg-gray-200 text-gray-800" }
const _hoisted_16 = { class: "text-xs px-1" }
const _hoisted_17 = {
  key: 3,
  class: "py-1",
  role: "none"
}
const _hoisted_18 = {
  class: "text-current group flex items-center px-2 pt-2 pb-1 text-sm font-semibold",
  tabindex: "-1"
}
const _hoisted_19 = { role: "none" }
const _hoisted_20 = ["title", "onClick"]
const _hoisted_21 = {
  key: 0,
  class: "border-b border-gray-200 mx-4 mt-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronDownIcon = _resolveComponent("ChevronDownIcon")!
  const _component_InformationCircleIcon = _resolveComponent("InformationCircleIcon")!
  const _component_DocumentTextIcon = _resolveComponent("DocumentTextIcon")!
  const _component_StarIcon = _resolveComponent("StarIcon")!
  const _component_ContractCustomAppendixFileAndPlaceholder = _resolveComponent("ContractCustomAppendixFileAndPlaceholder")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.isOpen)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpen = false)),
          tabindex: "-1",
          class: "fixed inset-0 z-20 w-full h-full cursor-default focus:outline-none"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass([
        'flex',
        'w-full',
        'h-full',
        (_ctx.valueField && _ctx.valueField.readOnly) || _ctx.caseReadOnly
          ? 'bg-fa-turquoise bg-opacity-20'
          : 'bg-control-gray-tint',
        'text-gray-800',
        'flex-col',
        'justify-center',
        'text-2xs'
      ])
    }, [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.node.values?.hasLabel)
          ? (_openBlock(), _createElementBlock("label", {
              key: 0,
              for: _ctx.node.values?.id || '' ,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isOpen = !_ctx.isOpen)),
              class: "pl-[3px] leading-4 text-3xs whitespace-nowrap mt-1"
            }, _toDisplayString(_ctx.node.values.label), 9, _hoisted_2))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("button", {
        type: "button",
        id: "menu-button",
        "aria-expanded": "true",
        "aria-haspopup": "true",
        class: "flex items-center text-2xs focus:outline-none focus:ring-1 focus:ring-fa-blue",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isOpen = !_ctx.isOpen)),
        disabled: (_ctx.valueField && _ctx.valueField.readOnly) || _ctx.caseReadOnly
      }, [
        (_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.t("contractNodeAppendixReference.adding")), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.selectedAppendicesSortOrder), 1)),
        _createVNode(_component_ChevronDownIcon, { class: "h-4 w-4 text-gray-600 self-center ml-auto" })
      ], 8, _hoisted_3)
    ], 2),
    (_ctx.isOpen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (_ctx.maxSelectedOptions)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", null, [
                  _createVNode(_component_InformationCircleIcon, { class: "text-fa-orange flex w-4 h-4" })
                ]),
                _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.t("contractNodeAppendixReference.maxSelected")), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.selectedOptions.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("div", {
                  class: "group flex items-center justify-end px-2 pt-1 text-xs cursor-pointer",
                  role: "menuitem",
                  tabindex: "-1",
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleClickOnNoAppendix && _ctx.handleClickOnNoAppendix(...args)))
                }, [
                  _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.t("contractNodeAppendixReference.noOption")), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.appendicesInCase.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.t("contractNodeAppendixReference.appendicesInCase")), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_13, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appendicesInCase, (appendix) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["group flex items-center px-3 py-2 text-xs", 
            appendix.selected
              ? 'bg-fa-blue text-white hover:bg-fa-blue/90 cursor-pointer'
              : _ctx.maxSelectedOptions
              ? 'text-gray-400 cursor-not-allowed'
              : 'text-gray-800 hover:bg-gray-100 cursor-pointer'
          ]),
                role: "menuitem",
                tabindex: "-1",
                key: appendix.id,
                onClick: ($event: any) => (_ctx.handleClickOnCaseAppendix(appendix.id))
              }, [
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("span", _hoisted_16, _toDisplayString(appendix.customAppendixNumber || appendix.sortOrder), 1)
                ]),
                _createTextVNode(" " + _toDisplayString(appendix.name), 1)
              ], 10, _hoisted_14))
            }), 128))
          ]),
          (_ctx.availableTemplates.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.t("contractNodeAppendixReference.availableTemplates")), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_19, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableTemplates, (template) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["group flex items-center px-3 py-2 text-xs cursor-pointer", 
            _ctx.maxSelectedOptions
              ? 'text-gray-400'
              : 'text-gray-800 hover:bg-gray-100 cursor-not-allowed'
          ]),
                title: 
            template.recommended
              ? _ctx.t('contractNodeAppendixReference.recommendedAppendix')
              : ''
          ,
                role: "menuitem",
                tabindex: "-1",
                key: template.id,
                onClick: ($event: any) => (_ctx.handleClickOnAvailableTemplate(template.id))
              }, [
                (!template.recommended)
                  ? (_openBlock(), _createBlock(_component_DocumentTextIcon, {
                      key: 0,
                      class: "mr-3 h-4 w-4 text-gray-500 flex-none"
                    }))
                  : (_openBlock(), _createBlock(_component_StarIcon, {
                      key: 1,
                      class: "mr-3 h-4 w-4 text-fa-orange"
                    })),
                _createTextVNode(" " + _toDisplayString(template.referenceNumber) + " " + _toDisplayString(template.name), 1)
              ], 10, _hoisted_20))
            }), 128)),
            (_ctx.availableTemplates.length || _ctx.appendicesInCase.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_21))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_ContractCustomAppendixFileAndPlaceholder, {
            "small-buttons": true,
            disabled: _ctx.maxSelectedOptions,
            onAppendixAdded: _ctx.onAppendixAdded
          }, null, 8, ["disabled", "onAppendixAdded"])
        ]))
      : _createCommentVNode("", true)
  ]))
}