import { ApiRequest, ApiError, ApiResponse } from "@/api/client";
import { ref, Ref } from "vue";
import { useToast } from "./toast";

type UseApiRequest<A extends unknown[], T> = {
  isLoading: Ref<boolean>;
  create: ({
    onSuccess,
    onError,
    onResponse
  }: {
    onSuccess?:
      | ((result: T) => void)
      | ((result: T) => Promise<void>)
      | undefined;
    onError?: ((error: ApiError) => void) | undefined;
    onResponse?: ((response: ApiResponse<T>) => void) | undefined;
  }) => {
    send: (...args: A) => void;
  };
};

export function useApiRequest<A extends unknown[], T>(
  apiRequestBuilder: (...args: A) => ApiRequest<T>,
  errorToast: false | { title: string; text?: string } = false,
  successToast: false | { title: string; text?: string } = false
): UseApiRequest<A, T> {
  const toast = useToast();
  const isLoading = ref(false);
  const sendRequest = async (
    args: A,
    onSuccess?: (result: T) => void,
    onError?: (error: ApiError) => void,
    onResponse?: (response: ApiResponse<T>) => void
  ) => {
    isLoading.value = true;

    const apiRequest = apiRequestBuilder(...args);
    const response = await apiRequest();
    if (onResponse) {
      onResponse(response);
    }

    if (response.error !== undefined) {
      errorToast && toast.error(errorToast.title, errorToast.text);
      if (onError) {
        onError(response.error);
      }
    } else if (onSuccess) {
      successToast && toast.success(successToast.title, successToast.text);
      onSuccess(response.result);
    }

    isLoading.value = false;
  };

  const create = ({
    onSuccess,
    onError,
    onResponse
  }: {
    onSuccess?: (result: T) => void;
    onError?: (error: ApiError) => void;
    onResponse?: (response: ApiResponse<T>) => void;
  }) => ({
    send: (...args: A) => {
      void sendRequest(args, onSuccess, onError, onResponse);
    }
  });

  return { isLoading, create };
}
