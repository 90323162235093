import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full h-full absolute bg-white items-center" }
const _hoisted_2 = { class: "flex items-center h-full overflow-hidden" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 0,
  class: "text-2xs flex-none flex"
}
const _hoisted_5 = { class: "ml-5 p-1 w-32 h-8 border border-gray-300" }
const _hoisted_6 = { class: "ml-5 p-1 w-32 h-8 border border-gray-300" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "break-words whitespace-normal markdown-container text-3xs mr-auto",
        innerHTML: _ctx.value
      }, null, 8, _hoisted_3),
      (_ctx.showSigningBoxes)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t("common.sign")), 1),
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.t("common.sign")), 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}