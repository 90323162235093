
import { ComputedNode } from "@/admin/lib/templateMapper";
import { computed, defineComponent, PropType, toRefs } from "vue";
import { renderMarkdown } from "@/utils/markdownHelpers";

export default defineComponent({
  props: {
    node: { type: Object as PropType<ComputedNode>, required: true },
    labelFor: { type: String, required: false }
  },
  setup(props) {
    const { node } = toRefs(props);

    const value = computed(() =>
      renderMarkdown(node.value.values?.text?.toString() ?? "")
    );

    return {
      value
    };
  }
});
