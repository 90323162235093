
import { ComputedNode } from "@/admin/lib/templateMapper";
import { computed, defineComponent, inject, PropType, ref, toRefs } from "vue";
import ContractNodeCheckmark from "./ContractNodeCheckmark.vue";
import { controlledComputed } from "@vueuse/core";
import { EntityFieldsKey, ValueFieldsKey } from "./types";

export default defineComponent({
  components: {
    ContractNodeCheckmark
  },
  props: {
    node: { type: Object as PropType<ComputedNode>, required: true },
    groupId: {
      type: String,
      required: false
    },
    exclusive: {
      type: Boolean,
      default: false
    },
    pageNumber: {
      type: Number as PropType<number>,
      required: true
    },
    showValidationFailures: {
      type: Boolean,
      default: false
    },
    caseReadOnly: {
      type: Boolean
    }
  },
  setup(props) {
    const { caseReadOnly } = toRefs(props);

    const nodeId = props.node.values?.id?.toString() ?? "";
    const valueFieldName = props.groupId ?? nodeId;
    const entityNameId = props.node.values?.entityNameId
      ? (props.node.values.entityNameId as number)
      : undefined;

    const valueFields = inject(ValueFieldsKey);
    const valueField = controlledComputed(
      () => !!valueFields?.value[valueFieldName],
      () => valueFields?.value[valueFieldName]
    );

    const entityFields = inject(EntityFieldsKey);
    const entityField = entityNameId
      ? controlledComputed(
          () => !!entityFields?.value[entityNameId],
          () => entityFields?.value[entityNameId]
        )
      : ref(undefined);

    const isReadOnly = computed(
      () =>
        caseReadOnly.value ||
        ((entityNameId
          ? entityField.value?.readOnly
          : valueField.value?.readOnly) ??
          false)
    );

    return {
      nodeId,
      valueField,
      entityField,
      valueFieldName,
      isReadOnly,
      entityNameId
    };
  }
});
