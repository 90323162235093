import { OkResponse } from "@/api/client/types";

export const downloadBlob = (
  response: OkResponse,
  fileName: string,
  type = "application/pdf"
): void => {
  const blob = new Blob([response.data as BlobPart], { type });
  const link = document.createElement("a");
  const blobUrl = window.URL.createObjectURL(blob);
  link.href = blobUrl;
  link.target = "_blank";

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  setTimeout(() => {
    window.URL.revokeObjectURL(blobUrl);
  }, 120 * 1000);
};

export const getBlobUrl = (response: OkResponse): string => {
  const blob = new Blob([response.data as BlobPart], {
    type: "application/pdf"
  });
  const blobUrl = window.URL.createObjectURL(blob);
  return blobUrl;
};

export const getBlob = (
  response: OkResponse,
  type = "application/pdf"
): Blob => {
  const blob = new Blob([response.data as BlobPart], {
    type
  });
  return blob;
};
