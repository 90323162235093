
import { defineComponent, computed, toRefs } from "vue";
import { renderMarkdown } from "@/utils/markdownHelpers";

export default defineComponent({
  name: "TextNode",
  props: {
    value: { type: String, required: true }
  },
  setup(props) {
    const { value } = toRefs(props);

    const compiledMarkdown = computed(() => renderMarkdown(value.value));

    return {
      compiledMarkdown
    };
  }
});
