
import { ComputedNode } from "@/admin/lib/templateMapper";
import { computed, defineComponent, inject, PropType, toRefs } from "vue";
import { InformationCircleIcon } from "@heroicons/vue/outline";
import { SetActiveHelpTextKey } from "./types";

export default defineComponent({
  components: {
    InformationCircleIcon
  },
  props: {
    node: { type: Object as PropType<ComputedNode>, required: true },
    index: { type: Number as PropType<number>, required: true }
  },
  setup(props) {
    const { node } = toRefs(props);

    const style = computed(() => ({
      top: `${node.value.top}px`,
      left: `${node.value.left}px`,
      width: `${node.value.width}px`,
      height: `${node.value.height}px`
    }));

    const setActiveHelpText = inject(SetActiveHelpTextKey);

    const onShowHelpText = () => {
      setActiveHelpText && setActiveHelpText(props.index);
    };

    return {
      style,
      onShowHelpText
    };
  }
});
